<div class="main-div">
    <div class="top-section">
         <img class="close" src="assets/icons/Cross_purple.svg" alt="cancel" (click)="submit(false)">
         <img src="../../../../assets/icons/info.png" alt="info" width="55">
         <p>{{message}} </p>
     </div>
     <div class="bottom-section">
         <button class="btn save_button" (click)="submit(true)">{{'valuationform.yes'| translate}}</button>
         <button class="btn save_button" (click)="submit(false)">{{'valuationform.no'| translate}}</button>
     </div>
 </div>