import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  constructor(public snackBar: MatSnackBar) { }
  show(message: string, action: string = 'Close', duration: number = 2000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
