export class BlockData {
    block_name: any;
    block_uid: string = "";
    lat: number = null;
    lon: number = null;
    neigh_uid: string = "";
    occupancy: number =0;
    shape_area: number =0;
    block_id: number =0;
    
    constructor(Values:any={}){
        Object.assign(this, Values);
    }
}