export const UserAction = {
        "drop_marker": { "Action_ID": 1, "Action_Type": "drop_marker" },
        "view_property_detail": { "Action_ID": 2, "Action_Type": "view_property_detail" },
        "track_property": { "Action_ID": 3, "Action_Type": "track_property" },
        "save_property": { "Action_ID": 4, "Action_Type": "save_property" },
        "share_property": { "Action_ID": 5, "Action_Type": "share_property" },
        "nearby": { "Action_ID": 6, "Action_Type": "nearby" },
        "neighborhood": { "Action_ID": 7, "Action_Type": "neighborhood" },
        "block": { "Action_ID": 8, "Action_Type": "block" },
        "property_visit": { "Action_ID": 9, "Action_Type": "property_visit" },
        "tour": { "Action_ID": 10, "Action_Type": "tour" },
        "compare_property": { "Action_ID": 11, "Action_Type": "compare_property" },
        "login": {"Action_id":12,"action_type":"login"},
        "logout": { "Action_ID": 13, "Action_Type": "logout" },
        "own_property": { "Action_ID": 14, "Action_Type": "own_property" },
        "track_location": { "Action_ID": 15, "Action_Type": "track_location" },
        "get_estimation": { "Action_ID": 16, "Action_Type": "get_estimation" },
}
