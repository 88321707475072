import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  message:string='';
  constructor(@Inject(MAT_DIALOG_DATA) public parentdata, public dialogRef: MatDialogRef<ConfirmationDialogComponent>) { 
    this.message = parentdata;
  }

 
  ngOnInit(): void {
  }

  submit(flag){
    this.dialogRef.close(flag);
  }
}
