import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CitySelectionDialogComponent } from 'src/app/core/components/city-selection-dialog/city-selection-dialog.component';
import { CityData } from 'src/app/home/models/cityData';
import { BrowserService } from '../../../services/browser.service';
import { ApiServiceService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-city-dialog-button',
  templateUrl: './city-dialog-button.component.html',
  styleUrls: ['./city-dialog-button.component.scss']
})
export class CityDialogButtonComponent implements OnInit {
  selectedCity: any;
  mobile: boolean;

  @Input() state:boolean;
  @Output() redirect = new EventEmitter()

  constructor(private api:ApiServiceService, private dialog: MatDialog, private browser: BrowserService,
    private localizeRouterService : LocalizeRouterService,
    private router:Router) { 
    this.mobile = this.api.isMobile;
  }

  ngOnInit(): void {
    this.api.selected_city.subscribe(city=>{
      if(city){
        this.selectedCity = city;
        this.selectedCity['hideClose'] = true;
      }else{
        this.selectedCity = {};
        this.selectedCity['hideClose'] = true;
      }
      
    })
  }
  
  changeCity(){
    const dialogRef = this.dialog.open(CitySelectionDialogComponent, {
      disableClose: true,
      // width: '85vw',
      maxWidth:'90vw',
      data: this.selectedCity,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if(this.state){
          if(res.slug) this.router.navigate([`/${res.slug}-${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE')}/estater-meter-gis`]);
          return;
        }
       let url =  `initial-load?city_slug=${res.slug}`;
       this.api.getData(url).subscribe({next: (city)=>{
          if(city && city.data && city.data.city){
            this.browser.setLocalValue('selected_city', JSON.stringify(new CityData(city.data.city)));
            this.api.selected_city.next(new CityData(city.data.city));
            this.browser.reload();
            // this.redirect.emit('/dashboard/settings/profile')
          }else{
            console.log(city.message);
          }
        },
        error:(err)=>{
            console.log(err.message);
        }});
      }
    });
  }

}
