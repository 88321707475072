<section class="query_page">
    
    <div class="container">
        <div class="position-relative"> <mat-progress-bar *ngIf="loader" style="position: absolute; top: 0; z-index: 1;  width: 100%;height: 5px;"
            mode="indeterminate"></mat-progress-bar></div>
        <form [formGroup]="contactForm" (ngSubmit)="save(contactForm.value)" class="card p-4" #f="ngForm">
            <div class="text-center"><h1>{{ title | translate}}</h1></div>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label for="name" class="lang_trans txt_position w-100"  >{{'submit_query.full_name'|translate}} <small class="text-danger">*</small> </label>
                        <input type="text" class="form-control lang_trans" formControlName="name" placeholder="{{'submit_query.full_name'|translate}}" />
                        
                            <div *ngIf="(submitted || (contactForm.controls.name.touched || contactForm.controls.name.dirty)) && contactForm.controls.name.errors" style="color:red">
                              
                            <div *ngIf="contactForm.controls.name.errors.required">{{'submit_query.Name_required' | translate}}</div>
                            <div *ngIf="contactForm.controls.name.errors.maxlength">{{'submit_query.Name_60_character' | translate}}</div>
                            <div *ngIf="contactForm.controls.name.errors.minlength">{{'submit_query.Name_2_character'| translate}}</div>
                            <div *ngIf="contactForm.controls.name.errors.pattern">{{'submit_query.Name_invalid'| translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="tell_us" class="lang_trans txt_position w-100">{{'submit_query.select_1'|translate}} <small class="text-danger">*</small></label>
                        <div class="custom-select">
                            <select formControlName="type" class="form-control" >
                                <option value="{{'submit_query.select_1_val1'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val1'|translate}}</option>
                                <option value="{{'submit_query.select_1_val2'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val2'|translate}}</option>
                                <option value="{{'submit_query.select_1_val3'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val3'|translate}}</option>
                                <option value="{{'submit_query.select_1_val4'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val4'|translate}}</option>
                                <option value="{{'submit_query.select_1_val5'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val5'|translate}}</option>
                                <option value="{{'submit_query.select_1_val6'|translate}}" class="selectvalue lang_trans">{{'submit_query.select_1_val6'|translate}}</option>
                            </select>
                            <div *ngIf="(submitted || (contactForm.controls.type.touched || contactForm.controls.type.dirty)) && contactForm.controls.type.errors" style="color:red; font-size: 14px;">
                                
                                <div *ngIf="contactForm.controls.type.errors.required">{{'submit_query.Type_required'| translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="subject" class="lang_trans txt_position w-100"  >{{'submit_query.subject'|translate}} <small class="text-danger">*</small></label>
                        <input type="text" class="form-control lang_trans" formControlName="subject" placeholder="{{'submit_query.subject'|translate}}" />
                        <div *ngIf="(submitted || (contactForm.controls.subject.touched || contactForm.controls.subject.dirty)) && contactForm.controls.subject.errors" style="color:red">
                            <div *ngIf="contactForm.controls.subject.errors.required">{{'submit_query.Subject_required'| translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phone_number" class="lang_trans txt_position w-100"  >{{'submit_query.phone'|translate}} <small class="text-danger">*</small></label>
                        <div class="d-flex">
                            <div class="input-box w-100">
                                <ngx-intl-tel-input 
                                    [cssClass]="'custom query_tel form-control'" 
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true" 
                                    [enablePlaceholder]="true" 
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.All]" 
                                    [selectFirstCountry]="true"
                                    [selectedCountryISO]="CountryISO.India" 
                                    [enableAutoCountrySelect]="true"
                                    [maxLength]="15"
                                    [phoneValidation]="true" 
                                    [separateDialCode]="true"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    [(ngModel)]="phoneNumber"
                                    formControlName="phone_number">
                                </ngx-intl-tel-input>     
                                <mat-error *ngIf="submitted && f.form.controls['phone_number'].hasError('required') && (f.form.controls['phone_number'].touched || f.form.controls['phone_number'].dirty)" style="color:red">
                                    {{ 'User.phone_required' | translate }}
                                  </mat-error>
                                  <mat-error *ngIf="(submitted || (f.form.controls['phone_number'].touched || f.form.controls['phone_number'].dirty)) && f.form.value.phone_number && f.form.controls['phone_number'].errors" style="color:red">
                                    {{ 'User.phone_invalid' | translate }}
                                  </mat-error>
                                  
                            </div>
                        </div>
                        <!-- <input type="text" maxlength="15"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                         class="form-control lang_trans" formControlName="phone_number" placeholder="{{'submit_query.phone'|translate}}" />
                        <div *ngIf="submitted && contactForm.controls.phone_number.errors" style="color:red" [class.hide]="show=='false'">
                            <div *ngIf="contactForm.controls.phone_number.errors.required">{{'submit_query.Mobile_Number_required'| translate}}</div>
                            <div *ngIf="contactForm.controls.phone_number.errors.maxlength">{{'submit_query.Mobile_number_then_15_character'| translate}}</div>
                        </div> -->
                    </div>
                </div>
              
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label for="email" class="lang_trans txt_position w-100"  >{{'submit_query.email_address'|translate}} <small class="text-danger">*</small></label>
                        <input type="email" class="form-control lang_trans" formControlName="email"  placeholder="info@example.com" />
                        <div *ngIf="(submitted || (contactForm.controls.email.touched || contactForm.controls.email.dirty)) && contactForm.controls.email.errors" style="color:red">
                            <div *ngIf="contactForm.controls.email.errors.required">{{'submit_query.Email_required'| translate}}</div>
                            <div *ngIf="contactForm.controls.email.errors.email">{{'submit_query.Email_must_valid_email_address'| translate}}</div>
                        </div>
                    </div>  
                    <div class="form-group">
                        <label for="attachments" class="lang_trans txt_position w-100"  >{{'submit_query.attachments_optional'|translate}}</label>
                        <div class="upload-btn-wrapper">
                            <div class="wrapper">
                               <p> {{'submit_query.drag_file' | translate}}</p>
                            </div>
                            <input type="file" #myFileInput accept="jpg, .jpeg, .png,.pdf,.doc,.docx,.csv,.xls,.xlsx" (change)="uploadImage($event)" >
                        </div>
                        <small>{{'submit_query.max_file_size' | translate}}</small>
                        <div><p>{{file_name}}</p></div>
                    </div>
                    <div class="form-group">
                        <label for="message" class="lang_trans txt_position w-100"  >{{'submit_query.description'|translate}} <small class="text-danger">*</small></label>
                        <textarea  class="form-control lang_trans" formControlName="message"  placeholder="{{'submit_query.description'|translate}}" ></textarea>
                        <div *ngIf="(submitted || (contactForm.controls.message.touched || contactForm.controls.message.dirty)) && contactForm.controls.message.errors" style="color:red">
                            <div *ngIf="contactForm.controls.message.errors.required">{{'submit_query.Description_required' | translate}}</div>
                            <div *ngIf="contactForm.controls.message.errors.maxlength">{{'submit_query.Description_250_character' | translate}}</div>
                        </div>
                    </div>
                                       
                </div>
            </div>
           
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="buttons">
                        <button class="btn button-default lang_trans" [disabled]="loader" >{{ !loader ? ('submit_query.submit' | translate) : ('submit_query.submiting' |translate) }}</button>
                        <button type="button" (click)="reset()" class="btn button-cancel lang_trans" >{{'submit_query.reset'|translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<app-alert></app-alert>