<div class="d-flex" [class.disableLang]="isModalOpen">
  <!-- <span *ngFor="let locale of locales" class="right-b" >
    <a class="p-1 mx-1 text-decoration-none  link-color text-capitalize" [class.activelang]="selected_lang == locale" [disabled]="language_loader"
      (click)="updateLang(locale)">
      {{locale}}
    </a>   
  </span> -->
  <ng-container *ngFor="let locale of locales">
  <span *ngIf="selected_lang != locale" style="font: normal normal 500 1rem/1rem Manrope;letter-spacing: 0px;color: #000000;">
    <a class="p-1 mx-1 text-decoration-none  link-color text-capitalize" [disabled]="language_loader"
      (click)="updateLang(locale)">
      {{(locale == 'ar')? 'عربي': 'English'}}
    </a>   
  </span>
  </ng-container>
</div>