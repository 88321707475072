import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { TourService } from 'ngx-tour-core';
import { Subscription } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api.service';
import { BrowserService } from 'src/app/services/browser.service';


@Component({
  selector: 'app-emfooter',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class EMFooterComponent implements OnInit {
  selected_lang: any = this.browser.getLocalValue(
    'LOCALIZE_DEFAULT_LANGUAGE'
  );
  langSubscriber: Subscription;
  public urlLang = '';
 
  constructor(public route:Router, private api:ApiServiceService,private browser:BrowserService) {
   
   }

  ngOnInit(): void { 
    this.langSubscriber = this.api.current_city_slug.subscribe(slug=>{
      this.urlLang = slug;
    })
  }

  ngOnDestroy(): void {    
    if(this.langSubscriber) this.langSubscriber.unsubscribe();
  }

}
