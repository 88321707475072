import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from '../alert';
import { AlertService } from './../alert.service';

@Component(
  {
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.css']
  })
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private alertService: AlertService, @Inject(PLATFORM_ID) private platformId: any) {
  }

  ngOnInit() {

    // subscribe to new alert notifications
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {

        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAfterRouteChange' flag
          this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          this.alerts.forEach(x => delete x.keepAfterRouteChange);
          return;
        }
        if (!this.alerts.length) {
          this.alerts = [alert];
        }
        else {
          for (let index = 0; index < this.alerts.length; index++) {
            const element = this.alerts[index];
            if (element.message === alert.message && element.type === alert.type) {
              return
            }
            else {
              this.alerts = [alert];
            }
          }
        }

        // auto close alert if required
        if (alert.autoClose) {
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => this.removeAlert(alert), 5000);
          }
        } else if (alert.time) {
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => this.removeAlert(alert), alert.time);
          }
        }

      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    if (this.alertSubscription) this.alertSubscription.unsubscribe();
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      alert.fade = true;

      // remove alert after faded out
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.alerts = this.alerts.filter(x => x !== alert);
        }, 250);
      }
    } else {
      // remove alert
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClasses(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success left-border-success',
      [AlertType.Error]: 'alert alert-danger left-border-danger',
      [AlertType.Info]: 'alert alert-info left-border-info',
      [AlertType.Warning]: 'alert alert-warning left-border-warning'
    }

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

}
