import { Component } from '@angular/core';

@Component({
  selector: 'app-page-500',
  template: ` 
  <div class="container-500">
   <div class="logo ps-4 pt-4">
    <a href="/"><img src="../assets/icons/logo_.png" alt="Estater Logo"></a>
   </div>
   <div class="d-flex justify-content-center">
   <div class="content">
   <img src ="/assets/apierrorimage/api-errorimage.svg">
        <br>
        <h4 class="text mt-3"><strong>Error!</strong></h4>
        <p>We're currently busy, Please try again in a moment.</p>
        <div class="text-center back-button" style="padding-top:5px;">
   <a href="/" class="btn backbutton">Take me back to the homepage</a>
   </div>
  </div>
  </div>
  </div>
    `,
  styles: [`
  .logo img {
    object-fit: contain;
    max-width: 12%;
  }
      .container-500{
        background: url('../assets/images/PAGE-500_bg.svg');
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .content {
      padding-top:0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #000000;
        max-width: 90%;
        height: calc(100vh - 60px);
      }
      .content img {
        width:80%;
      }
      .container-500 .status-code {
        font-size: 16rem;
        bottom: 25%;
        position: relative;
      }
      .container-500 .text {
        font-size: 2.3rem;
      }
    .content p{
      font-weight:700;
        font-size: 0.9rem;
        line-height:1.4;
    }
    .backbutton{
      padding-top:15px;
      background:#664fd9;
      color:#fff;
      border:#664fd9;
      font-size:1rem;
      width:311px;
      height:55px;  
    }
    /* Responsive scss */

    @media screen and (max-width: 1025px) {
      .container-500 .status-code {
        font-size: 12rem;
      }
      .container-500 .text {
        font-size: 2.31rem;
      }
      .content p{
        font-size: 1.2rem;
    }
    }

    @media screen and (max-width: 767px) {
      .container-500 {
        width: 100%;
        height: 100vh;
        background: url('../assets/images/Page-404-Mobile.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
      }
      .logo img {
        object-fit: contain;
        max-width: 30%;
      }
      .container-500 .status-code {
        font-size: 8rem;
      }
      .container-500 .text {
        font-size: 2.0rem;
      }
    }`
  ]
})
export class Page500Component {
  
  constructor() {}
}
