import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiServiceService } from 'src/app/services/api.service';
import { BrowserService } from 'src/app/services/browser.service';
import { ShareService } from 'src/app/services/share.service';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider } from "@abacritt/angularx-social-login";
import { environment } from '../../../../../environments/environment';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
  isMobile: any;
  loading: boolean;
  google_login_url = '';
  slug = '';
  private unsubscribe$:Subject<void>=new Subject<void>();
  @Output() close = new EventEmitter();

  constructor(
    private api: ApiServiceService,
    private share: ShareService,
    private browserService: BrowserService,
    private authService: SocialAuthService
    ) {
    this.isMobile = this.api.isMobile;
  }

  ngOnInit(): void {
    this.api.current_city_slug.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
    ).subscribe(slug=>{
      this.slug = slug;
    })
  }

  signInWithGoogle(): void {
    this.google_login_url = `${environment.umsUrl}google?app_id=10&cityslug=${this.slug}`;
    window.location.href=this.google_login_url;
  }

  signInWithFB(): void {
    try {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((fbData: any) => {
          //console.log('facebook user data >> ', fbData)
          if (fbData) {
            let data1 = {
              accessToken: fbData.authToken,
              userID: fbData.id,
              provideName: 'facebook_login',
            };
            this.socialLogin(data1);
          }
        })
        .catch(err => {
          //console.log(err);
        });
    } catch (error) {
      //console.log(error);
    }

  }

  // social Login
  socialLogin(provider: any) {
    var body = {
      access_token: provider.accessToken,
      user_social_id: provider.userID,
      login_type: provider.provideName,
    };
    this.loading = true;
    this.api.postUmsData(`social-login`, body).subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res.data) {
          //this.checkBrokerLogin(res.data);
          this.setUserInfo(res.data)
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }

  setUserInfo(userdata: any) {

    let first_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].first_name : '';
    let last_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].last_name : '';

    if (userdata.user_details && userdata.user_details[this.api.language_id]) {
      first_name = userdata.user_details[this.api.language_id].first_name;
      last_name = userdata.user_details[this.api.language_id].last_name;
    }

    let user_info = {
      created_date: userdata.created_date,
      date_of_birth: userdata.date_of_birth,
      department_id: userdata.department_id,
      first_name: first_name,
      gender: userdata.gender,
      is_verified: userdata.is_verified,
      last_name: last_name,
      lat: userdata.lat,
      lon: userdata.lon,
      nationality: userdata.nationality,
      phone_number: userdata.phone_number,
      primary_email_id: userdata.primary_email_id,
      profile_image: userdata.profile_image,
      token: userdata.token,
      user_id: userdata.user_id,
    };
    this.browserService.setLocalValue('user_id', userdata.user_id);
    this.browserService.setLocalValue('user_token', userdata.token);
    this.browserService.setLocalValue('user_info', JSON.stringify(user_info));
    this.browserService.setLocalValue('profile_image', userdata.profile_image);
    this.api.profile_image.next(userdata.profile_image);
    this.api.user_info.next(user_info);
    // this.share.getSavedProperties();
    this.share.selected_property.subscribe(ele => {
      if (ele) {
        if (this.share.landing_obj) this.share.landing_obj.createCluster();
      }
    })
    this.close.emit();
  }
}
