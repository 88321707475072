import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ShareService } from 'src/app/services/share.service';
import { BrowserService } from 'src/app/services/browser.service';
import { MatDialog } from '@angular/material/dialog';
import { UserComponent } from '../../../../home/component/user/user.component';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['../pages.scss']
})
export class TermsComponent implements OnInit {
  private unsubscribe$:Subject<void>=new Subject<void>();
  mobile: boolean;
  selectedSection:string='';
  user_info:any;
  profile_image:string='assets/icons/user.svg';
  constructor(private api:ApiServiceService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private share: ShareService,public dialog: MatDialog,  private browser:BrowserService,
    private route: Router) { 
      this.mobile = this.api.isMobile;
      this.api.user_info.subscribe((user_info:any)=>{
        if(user_info){
          this.user_info = user_info;
          this.profile_image = this.user_info.profile_image;
        }
      })
    }
    ngOnInit(): void {
      this.share.selected_section.pipe(takeUntil(this.unsubscribe$)).subscribe((data:string)=>{
        this.selectedSection = data;
      })
    }
    openDialog() {
      if(this.api.user_id){
          this.route.navigateByUrl('/dashboard')
      }else{
        this.dialog.open(UserComponent,{
          width: '430px',
          data: 'login',
          disableClose: true
        });
      }
  }

  ngOnDestroy(): void { 
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // open_save_dailog(){
  //     this.share.landing_obj.open_dialog();
  // }

 

}
