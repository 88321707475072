    <div style="display: none" *ngIf="isServer">
        <!-- <app-header></app-header> -->
        <router-outlet>
            <app-alert></app-alert>
        </router-outlet>
     </div>
     
     <div *ngIf="isBrowser">
        <!-- <app-header></app-header> -->
        <router-outlet>
            <app-alert></app-alert>
        </router-outlet>
     </div>