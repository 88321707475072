<!-- New city selection page  HTML start from here  -->

<section class="background-image isOpened">
    <div class="close" *ngIf="!hideClosebutton" title="Close"><img (click)="close()"src="assets/icons/cross.svg" alt="cross"></div> 
    <div class="country-selection-wrapper">
      <div
        class="country-name container-fluid d-flex flex-column align-content-center"
      >
        <h1 class="text-center heading">
          {{ "Home.choose_country" | translate }}
        </h1>
        <ul class="city-cards">
          <ng-container *ngFor="let city of cities">
            <ng-container *ngIf="city?.child_data; else countryData0">
              <ng-container *ngFor="let child_city of city.child_data">
              <li (click)="select_city(child_city)"
                [ngClass]="{'active': child_city.place_id == city_id}"
                class="shadow-sm mb-5 bg-white d-flex flex-column align-content-center"
              >
              <div class="country_name_overflag">{{city.place_name}}</div>
                <div class="country-item">
                  <img [src]="child_city.image?child_city.image:city.image" />
                </div>
                <button class="action-btn">
                  {{ "city." + child_city.place_name | translate }}
                </button>
              </li>
              </ng-container>
            </ng-container>
            <ng-template #countryData0>
              <li (click)="select_city(city)"
                [ngClass]="{'active': city.place_id == city_id}"
                class="shadow-sm mb-5 bg-white d-flex flex-column align-content-center"
              >
                <div class="country-item">
                  <img [src]="city.image" />
                </div>
                <button class="action-btn">
                  {{ "city." + city.place_name | translate }}
                </button>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </div>
    </div>
  </section>
  <!-- New city selection page  HTML end  here  -->