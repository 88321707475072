export class ValutionData {
    land_area: number = 0; // la
    vacant: string = 'no'; // vc
    builtup_area: number = 0; // ba
    type_of_property: string = 'standard'; // top
    floor: number = 1; // f
    basement: string = 'no_basement'; // bs
    building_age: string = '6_to_10';  // bage
    finish_quality: string = 'standard'; // fq
    current_use: string = ''; // cu
    rental_income: number = 0; // ri
    basement_uses: string = ''; // bu
    shaded_parking: number = 0; // sp
    leasable_area: number = 0; // leas
    mezzanine: number = 0; // mez
    parking: number = 0; // par
    prop_id:string='';//pid
    inv_form:any='';//invf

    constructor(Values:any={}){
        Object.assign(this, Values);
    }
}

export class ValutionSortData{
    la: number = 0;
    vc: string = 'no';
    ba: number = 0;
    top: string = 'standard';
    fq: string = 'standard';
    bage: string = '6_to_10';
    f: number = 1;
    cu: string = '';
    ri: number = 0;
    bs: string = 'no_basement';
    bu: string = '';
    sp: number = 0;
    leas: number = 0;
    mez: number = 0;
    par: number = 0;
    pid: string = '';
    invf: any = '';

    constructor(Values:any={}){
        Object.assign(this, Values);
    }
}
