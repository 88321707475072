import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from '../../../services/api.service';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonfunctionService } from '../../../services/commonFunctionService'
import { BrowserService } from 'src/app/services/browser.service';
import { ValutionSortData } from 'src/app/home/models/valuation_data';
import { ShareService } from 'src/app/services/share.service';
import { Subject, Subscription } from 'rxjs';


@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html'
})
export class SelectLanguageComponent {
  private unsubscribe$:Subject<void>=new Subject<void>();
  selected_lang='';
  currentUrl='';
  locales = this.localizeRouterService.parser.locales;
  
  language_loader: boolean;
  mobile: boolean;
  isModalOpen: boolean;
  preference ='';    
  routerSubscriber:Subscription;

  constructor(public translate: TranslateService, private api: ApiServiceService, private localizeRouterService: LocalizeRouterService,
    private router: Router, private fnc: CommonfunctionService,
    private browser:BrowserService,
    private share: ShareService) { 
     
    this.mobile = this.api.isMobile;
    this.api.lang.pipe(takeUntil(this.unsubscribe$)).subscribe((lang) => {
      this.translate.use(lang);
      this.selected_lang=lang;
    });
    // this.router.events
    //       .subscribe(
    //         (event: NavigationEnd) => {
    //           if(event instanceof NavigationEnd) {
    //             this.setCurrentUrl();            
    //           }
    //         });
  }

  updateLang(val:any){
    
    if(this.selected_lang == val){
      return;
    }
    this.setCurrentUrl(val);
    // this.browser.setLocalValue('LOCALIZE_DEFAULT_LANGUAGE', val);
    this.language_loader=true;
    let queryParams='';
    let bid = this.fnc.getParameterByName('bid');
    let pid = this.fnc.getParameterByName('pid');
    let preference = this.fnc.getParameterByName('preference');

    if(bid) queryParams =  `?bid=${bid}`;
    if(bid && pid) queryParams =  `?bid=${bid}&pid=${pid}`;
    if(bid && pid && preference) queryParams = queryParams+`&preference=${preference}`
    this.api.lang.next(val);  
  
    if(val == 'ar'){
      
      if(this.currentUrl == '/'){
        this.currentUrl = "/"+val;  
      }else{
        this.currentUrl = this.currentUrl+queryParams;  
      }
      // this.localizeRouterService.settings.alwaysSetPrefix=false;
      // this.localizeRouterService.changeLanguage('ar')   
      this.router.navigateByUrl(this.currentUrl)
    }else{ 
      // this.localizeRouterService.settings.alwaysSetPrefix=false;
      // this.localizeRouterService.changeLanguage('en')     
      this.currentUrl = this.currentUrl+queryParams;
      this.router.navigateByUrl(this.currentUrl)
      
      // this.brower.reload()
    }        
    this.language_loader=false;
    //this.brower.reload()
   
  }

  ngOnInit(): void {
    this.share.isValuationModalOpen.pipe(takeUntil(this.unsubscribe$))
    .subscribe((isOpen)=>{
      this.isModalOpen = isOpen;
    })
    this.share.valuation_data.pipe(takeUntil(this.unsubscribe$)).subscribe(e=>{
      let valuation_data = e;
      if(valuation_data){
        let valutionsortdata = new ValutionSortData();
        valutionsortdata = this.fnc.createValutionSortData(valuation_data);
        // this.preference = encodeURIComponent(JSON.stringify(valutionsortdata));
      }else{
        this.preference ='';
      }
    });

    // this.setCurrentUrl();
    this.routerSubscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.isModalOpen = false;
    });    
  }


  private setCurrentUrl(lang): void {
    let selected_city_history = (this.browser.getLocalValue('selected_city')) ? JSON.parse(this.browser.getLocalValue('selected_city')) : null;
    if (selected_city_history) {
      let slug = selected_city_history.slug;
      let lan = this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
      let url: string = this.router.url;
      if(lang == 'ar' && url.includes('dashboard')){
        this.currentUrl = lang+url.split('?')[0];
      } else if(lang == 'en' && url.includes('dashboard')){
        this.currentUrl = (url.split('?')[0]).split("/"+lan)[1];
      } else {
        this.currentUrl = url.replace(`${slug}-${lan}`, `${slug}-${lang}`)
        .split('?')[0];
      }
    }
  }
  ngOnDestroy(){
    if(this.routerSubscriber)this.routerSubscriber.unsubscribe();
    this.mobile;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }
}