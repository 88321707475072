import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class CommonfunctionService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = -1;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj:any, k:any) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }
  // move array element by changing it's index
  array_move(arr:any, old_index:any, new_index:any) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    arrayObj?.forEach((obj:any) => {
      if (obj[key]?.toString().toLowerCase() == val?.toString().toLowerCase() && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  removeDuplicateKey(arr: Array<any>) { //get unique array
    let uniqueArray:any = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }
  // remove duplicate object key - val
  uniqueObject(key: any, arr: Array<Object>) { //get unique array
    let _this = this;
    let uniqueArray:any = [];
    arr.forEach(function (element: any) {
      if (_this.getArrayindex(key, element[key], uniqueArray) == null) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

  getArrayValueNull(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj:any) => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach((obj:any) => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach((obj:any) => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj:any) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value:any) {
    value = new Date(value);
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }
 //accepting only date Object
 dateOfBirthFormat(date:any) {
  var value:any = new Date(date);
  return value.getDate().toString().padStart(2, 0) + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getFullYear();
}

  // copy json object without reference
  deepCopyJson(obj:any) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }
    
  getInitials(e:any) {
    let str = e;
    if (str) {
      let splited = str.split(' '), newStr: any = [], count = 0;
      if (splited.length >= 2) {
        splited.forEach((v) => {
          if (count < 2) {
            newStr.push(v.charAt(0));
            count++;
          }
        })
      } else {
        let char = splited[0];
        newStr.push(char.charAt(0))
        newStr.push(char.charAt(1))
      }
      let initials = newStr[0] + newStr[1];
      return initials;
    }
    return '';
  }

  isNumber(val){
    if(Number(val)){
      return true;
    }
    return false;
  }

  modifyInt(options:any) {
    const { value, roundUp, convertToLocaleString, floatingPoint } = options;
    let initialValue = value;
    if(isPlatformBrowser(this.platformId)){
      if(this.isNumber(value)){
        if (value === 0) initialValue = 0;
        if (roundUp === true) initialValue = Math.ceil(+initialValue);
        if (roundUp === false) initialValue = Math.round(+initialValue);
        if (floatingPoint > 0) initialValue = initialValue.toFixed(floatingPoint);
        if (convertToLocaleString === true || convertToLocaleString === undefined) initialValue = Number(initialValue).toLocaleString('en-us');
      }
    }   
    return initialValue;
  }

  getAddress(own_address:any){
    let address = '';
    if(own_address && own_address.address_text){
      address = own_address.address_text;
      return address;
    }else if(own_address){
      if (own_address['house_no']) address += 'House No ' + own_address.house_no + ', ';
      if (own_address['building_name']) address += ' ' + own_address.building_name + ", ";
      if (own_address['lane_name']) address += '' + own_address.lane_name + ", ";
      if (own_address['street_name']) address += '' + own_address.street_name + ", ";
      if (own_address['parcel_no']) address += 'Parcel ' + own_address.parcel_no + ", ";      
      if (own_address['block_name']) address += 'Block ' + own_address.block_name + ", ";
      if (own_address['neigh_name']) address += '' + own_address.neigh_name;     
      return address;
    } 
  }
  getSetbackArea(p) {
    let setBackArea = null;
    if(!p.parcel_data) return null;
    let parcel_data = p.parcel_data;
    if(parcel_data == null || parcel_data == undefined){
        return null;
    }
    let arr = Object.keys(parcel_data);         
    if (arr.includes('merged_parcel') && arr.includes('merged_setback')) {
        setBackArea = parcel_data['merged_setback'];
    } else {
        setBackArea =(parcel_data['setback_area'] == undefined || parcel_data['setback_area'] == null) ? 0 : parcel_data['setback_area'];
    }
    setBackArea = (setBackArea > 10) ? this.modifyInt({ "value": setBackArea, roundUp: true}) : null;
    return setBackArea;
  }
  getMergedParcel(p) {
    let mergedParcels = null;
    if(!p.parcel_data) return null;
    let parcel_data = p.parcel_data;
    if (!parcel_data) return;
    let arr = Object.keys(parcel_data);
    if (arr.includes('merged_parcel')) {
        mergedParcels = parcel_data['merged_parcel'];
    }
    return mergedParcels;
  }
  getFrontage(data:any){
    let frontage=0;
    let obj = (typeof data === 'object')
    if(obj){
        let obj = Object.keys(data);
        obj.map(ele=>{
          frontage += data[ele];
        })
        return this.modifyInt({value : frontage, roundUp:true});
    }else {
      return data? data : frontage;
    }
  }

  //Add or modify querystring
changeUrl(key,value, neigh_name=null) {
  if (isPlatformBrowser(this.platformId)) {
  //Get query string value  
  let searchUrl=location.search, urlValue = document.location.href;  
	if(searchUrl.indexOf("?") == -1) {
    // if(neigh_name){
    //   urlValue = `${urlValue}/${neigh_name}`
    // }
		urlValue= urlValue + '?'+key+'='+value;
		history.pushState({state:1, rand: Math.random()}, '', urlValue);
	}
	else {
		//Check for key in query string, if not present
		if(searchUrl.indexOf(key)== -1) {
			urlValue=urlValue+'&'+key+'='+value;
		}
		else {	//If key present in query string
			let oldValue = this.getParameterByName(key);
			if(searchUrl.indexOf("?"+key+"=")!= -1) {
				urlValue = urlValue.replace('?'+key+'='+oldValue,'?'+key+'='+value);
			}
			else {
				urlValue = urlValue.replace('&'+key+'='+oldValue,'&'+key+'='+value);	
			}
		}
		history.pushState({state:1, rand: Math.random()}, '', urlValue);
	}
}
}

//Function used to remove querystring
removeQString(key:any) {
  if (isPlatformBrowser(this.platformId)) {
	let urlValue=document.location.href,searchUrl: string,removeVal,oldValue;
	//Get query string value
  searchUrl=location.search;	
	if(key.length) {
    key.map(ele=>{      
      oldValue = this.getParameterByName(ele);      
      removeVal=(ele+"="+oldValue).toString();      
      if(searchUrl.indexOf('?'+removeVal+'&')!= -1) {
        urlValue=urlValue.replace('?'+removeVal+'&','?');
      }
      else if(searchUrl.indexOf('&'+removeVal+'&')!= -1) {
        urlValue=urlValue.replace('&'+removeVal+'&','&');
      }
      else if(searchUrl.indexOf('?'+removeVal)!= -1) {
        urlValue=urlValue.replace('?'+removeVal,'');
      }
      else if(searchUrl.indexOf('&'+removeVal)!= -1) {
        urlValue=urlValue.replace('&'+removeVal,'');
      }
    })	
	}
	else {
		 searchUrl=location.search;
		urlValue=urlValue.replace(searchUrl,'');
  }  
  history.pushState({state:1, rand: Math.random()}, '', urlValue);
}
}

//Get querystring value
getParameterByName(name) {
  if (isPlatformBrowser(this.platformId)) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    if(name == 'preference'){
      return results === null ? "" : results[1];
    }else{
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
  }
}
//create zone data
renderZone(zoneObj) {
  if (zoneObj && zoneObj['zoning']) {
      let filtered = zoneObj['zoning'].filter(function (el) { // remove null value
          return el != null;
        });
      return filtered.join(', ');
  } else {
      return '--';
  }
}
getDataToRender(tableData:any) {
  let data = {};
  if (!tableData['direction']) return;
  let direction = tableData['direction'];
  let arr = Object.keys(direction);
  data['corner'] = tableData['corner'];
  if (tableData['no_of_parcel'] > 1) {
      data['no_of_parcel'] = tableData['no_of_parcel'];
  }
  data['set_back'] = (tableData['set_back'] == 0 || tableData['set_back'] == null || tableData['set_back'] == undefined) ? 'Limited' : tableData['set_back'] + ' sqm';
  

  arr.forEach((item) => {
      let directionValue = +direction[item];
      
      if (directionValue) {
          if (item == 'e') {
              data['east_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'w') {
              data['west_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'n') {
              data['north_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 's') {
              data['south_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'ne') {
              data['northeast_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'nw') {
              data['northwest_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'se') {
              data['southeast_frontage'] = `${directionValue.toFixed(2)} m`;
          }
          if (item == 'sw') {
              data['southwest_frontage'] = `${directionValue.toFixed(2)} m`;
          }
      };

  })
  return data;
}

getlanlon(centroid:any){
  if(!centroid) return;
  let cord = String(centroid);
  let c = cord.split(' ');
  let lon = c[0].split('POINT(')[1];
  let lat =c[1].split(')')[0]
  return {lat:lat,lon:lon};
}

  // create sort data of vation 
  createValutionSortData(data) {
    return {
      la: data.land_area,
      vc: data.vacant,
      ba: data.builtup_area,
      top: data.type_of_property,
      fq: data.finish_quality,
      bage: data.building_age,
      f: data.floor,
      cu: data.current_use,
      ri: data.rental_income,
      bs: data.basement,
      bu: data.basement_uses,
      sp: data.shaded_parking,
      leas: data.leasable_area,
      mez: data.mezzanine,
      par: data.parking,
      pid: data.prop_id,
      invf: data.inv_form,
    }
  }

  createValutionData(data: any) {
    return {
      land_area: data.la,
      vacant: data.vc,
      builtup_area: data.ba,
      type_of_property: data.top,
      floor: data.f,
      basement: data.bs,
      building_age: data.bage,
      finish_quality: data.fq,
      current_use: data.cu,
      rental_income: data.ri,
      basement_uses: data.bu,
      shaded_parking: data.sp,
      leasable_area: data.leas,
      mezzanine: data.mez,
      parking: data.par,
      prop_id: data.pid,
      inv_form:data.invf,
    }
  }  

  public createbbox(data: any): string {
    let lat_list: any = [], lon_list: any = [];
    data.map((ele: any) => {
      lat_list.push(ele[1])
      lon_list.push(ele[0])
    })
    let minlat = this.min(lat_list);
    let maxlat = this.max(lat_list);
    let minlon = this.min(lon_list);
    let maxlon = this.max(lon_list);
    let bbox = `${minlat},${minlon},${maxlat},${maxlon}`
    return bbox;
  }

  public max(array: any) {
    let max = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i] > max) {
        max = array[i];
      }
    }
    return max;
  }
  public min(array: any) {
    let min = array[0];
    for (let i = 0; i < array.length; i++) {
      if (array[i] < min) {
        min = array[i];
      }
    }
    return min;
  }

  removeSpace(val:string){
    if(val){
      return val.replace(/\s+/g, '-').toLowerCase();
    }
    return ''
  }
}

 
