<div class="header_top">
    <div class="row">
        <div class="col-md-12">
            <div class="topbar">
                <span class="d-flex">
                    <a href="/">
                        <img class="logo" src="assets/icons/logo.svg" alt="estater" />
                    </a>
                </span>
                <div tourAnchor="account" (click)="openDialog()" class="user_icon"><img class="user-icon" src="{{profile_image}}"
                        alt="profile_image" onerror="this.src='assets/icons/user.svg'" /></div>
            </div>
        </div>
    </div>
</div>
<div class="main_container txt_position">
    <section class="case-study-header">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>{{'terms.term_head' | translate}}</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container">
            <div class="eula-content p-3">
                <p class="textp">
                    {{'terms.term_head_p1' | translate}}
                </p>
                <ol class="main_ol">
                    <li>
                        <p class="main-heading">{{'terms.group_role' | translate}}</p>
                        <p class="textp">
                            {{'terms.group_role_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">{{'terms.registration' | translate}}</p>
                        <p class="textp">
                            {{'terms.registration_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">{{'terms.restrictions' | translate}}</p>
                        <ol type="a" class="lower-alpha" id="three">
                            <li>
                                <p class="main-heading">
                                    {{'terms.restrictions_head' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.restrictions_p1' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading"> {{'terms.mobile_app' | translate}}</p>
                                <p class="textp">
                                    {{'terms.mobile_app_p1' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.use_content' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.use_content_p1' | translate}}
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading"> {{'terms.prohibited_use' | translate}} </p>
                        <ol type="1" class="mb-2">
                            <li>
                                {{'terms.prohibited_use_p1' | translate}}
                                <ol class="final_inner_list">
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_1' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_2' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_3' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_4' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_5' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_6' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_7' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_8' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_9' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_10' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_11' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_12' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_13' | translate}}</p>
                                    </li>
                                    <li>
                                        <p class="textp">{{'terms.prohibited_use_14' | translate}}</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">{{'terms.fees' | translate}}</p>
                        <ol type="a" class="lower-alpha" id="five">
                            <li>
                                <p class="main-heading">
                                    {{'terms.general' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.general_p1' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.subscriptions' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.subscriptions_p1' | translate}}
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.user_materials' | translate}}
                        </p>
                        <ol type="a" class="lower-alpha six">
                            <li>
                                <p class="main-heading">{{'terms.license' | translate}}</p>
                                <p class="textp">
                                    {{'terms.license_p1' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.disclaimer' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.disclaimer_p1' | translate}}
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.sent_info' | translate}}
                        </p>
                        <ol type="a" class="lower-alpha" id="seven">
                            <li>
                                <p class="main-heading">
                                    {{'terms.general_2' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.general_2_p1' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.certain_service' | translate}}
                                </p>
                                <ol class="lower_alpha">
                                    <li>
                                        <span class="main-heading">
                                            {{'terms.financial_service' | translate}}
                                        </span>
                                        <p class="textp">
                                            {{'terms.financial_service_p1' | translate}}
                                        </p>
                                    </li>
                                    <li>
                                        <span style="text-decoration: underline;" class="main-heading">
                                            {{'terms.rental_products' | translate}}
                                        </span>
                                        <p class="textp">
                                            {{'terms.rental_products_p1' | translate}}
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.additional_terms' | translate}}
                                </p>
                                <p class="textp" style="margin-bottom: 0;">
                                    {{'terms.additional_terms_p1' | translate}}
                                </p>
                                <ol class="lower_alpha">
                                    <li>
                                        <span class="main-heading">
                                            {{'terms.windows' | translate}}
                                        </span>
                                        <p class="textp">
                                            {{'terms.windows_p1' | translate}} <a class="third-party"
                                                href="http://www.microsoft.com/maps/assets/docs/terms.aspx.">http://www.microsoft.com/maps/assets/docs/terms.aspx.</a>
                                        </p>
                                    </li>
                                    <li>
                                        <span class="main-heading">
                                            {{'terms.google_maps' | translate}}
                                        </span>
                                        <p class="textp p_align_left">
                                            <span>{{'terms.google_maps_p1' | translate}} </span>
                                            <a class="third-party" href="http://www.google.com/intl/en_us/help/terms_maps.html">http://www.google.com/intl/en_us/help/terms_maps.html</a>
                                            <span>{{'terms.google_policy' | translate}} </span>
                                            <a class="third-party" href="https://www.google.com/intl/ALL/policies/privacy/index.html.">https://www.google.com/intl/ALL/policies/privacy/index.html.</a>
                                        </p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.intellectual_property' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.intellectual_property_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.feedback' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.feedback_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.claims' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.claims_p1' | translate}}
                        </p>
                        <ol class="final_inner_list">
                            <li>
                                <p class="textp">{{'terms.documentation' | translate}}</p>
                            </li>
                            <li>
                                <p class="textp">{{'terms.documentation_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="textp">{{'terms.documentation_p2' | translate}}</p>
                            </li>
                            <li>
                                <p class="textp">{{'terms.documentation_p3' | translate}}</p>
                            </li>
                        </ol>
                        <br />
                        <p class="textp">
                            {{'terms.documentation_p4' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.documentation_p5' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.documentation_p6' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading"> {{'terms.agreement' | translate}}</p>
                        <p class="textp">
                            {{'terms.agreement_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">{{'terms.privacy_policy' | translate}}</p>
                        <ol type="a" class="lower-alpha" id="twelve">
                            <li>
                                <p class="main-heading">
                                    {{'terms.privacy_policy_p1' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.privacy_policy_p2' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="main-heading">
                                    {{'terms.other_terms' | translate}}
                                </p>
                                <p class="textp">
                                    {{'terms.other_terms_p1' | translate}}
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.indemnification' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.indemnification_p1' | translate}}
                        </p>
                        <ol type="a" id="thirteen" class="final_inner_list">
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p2' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p3' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p4' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p5' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p6' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p7' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.indemnification_p8' | translate}}
                                </p>
                            </li>
                        </ol>
                        <br />
                        <p class="textp">
                            {{'terms.indemnification_p9' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.no_warrenties' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.no_warrenties_p1' | translate}}
                        </p>
                        <ol type="A" id="no-warranty" class="final_inner_list">
                            <li>
                                <p class="textp"> {{'terms.no_warrenties_p2' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.no_warrenties_p3' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.no_warrenties_p4' | translate}}
                                </p>
                            </li>
                        </ol>
                        <br />
                        <p class="textp">
                            {{'terms.no_warrenties_p5' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.limitation' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.limitation_p1' | translate}}
                        </p>
                        <ol type="A" id="remedy" class="final_inner_list">
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p2' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p3' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p4' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p5' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p6' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p7' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p8' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p9' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p10' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p11' | translate}}
                                </p>
                            </li>
                            <li>
                                <p class="textp">
                                    {{'terms.limitation_p12' | translate}}
                                </p>
                            </li>
                        </ol>
                        <br />
                        <p class="textp">
                            {{'terms.limitation_p13' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.consent_communications' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.consent_communications_p1' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.consent_communications_p2' | translate}}

                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.governing_law' | translate}}
                        </p>
                        <p class="textp">
                            {{'terms.governing_law_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading"> {{'terms.juridiction' | translate}}
                        </p>

                        <p class="textp">
                            {{'terms.juridiction_p1' | translate}}
                        </p>
                        <p class="textp eighteen-end">
                            {{'terms.rights' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">
                            {{'terms.violations' | translate}}
                        </p>
                        <p class="textp mb-5">
                            {{'terms.violations_p1' | translate}}
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </section>
</div>
<ng-container *ngIf="!mobile">
    <app-footer></app-footer>
</ng-container>