import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ApiServiceService } from '../../../../services/api.service';
import { BrowserService } from '../../../../services/browser.service';
import { ShareService } from '../../../../services/share.service';
import { AlertService } from 'src/app/core/components/alerts/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UserComponent } from '../user.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isMobile: boolean = false;
  public loginForm!: UntypedFormGroup;
  auth2: any;
  page_name: string;
  pagename: BehaviorSubject<any> = new BehaviorSubject<string>('')
  @Input() page: string;
  @Output() register = new EventEmitter();
  loading: boolean = false;
  captchaError: boolean = false;
  recaptchaActive = environment.recaptchaActive
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    private browserService: BrowserService,
    private api: ApiServiceService,
    private _fb: UntypedFormBuilder,
    private share: ShareService,
    private el: ElementRef,
    private alertService: AlertService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserComponent>
  ) {
    this.isMobile = this.api.isMobile;
  }

  ngOnInit(): void {
    this.pagename.next(this.page)
    this.pagename.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      this.page_name = res;
    });

    this.loginForm = this._fb.group({
      email: [null, [Validators.email, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, [Validators.required]],
      remember_me: [true],
      recaptchaReactive: [null, Validators.required]
    });

    if (!this.recaptchaActive) {
      this.loginForm.get('recaptchaReactive').clearValidators();
      this.loginForm.get('recaptchaReactive').updateValueAndValidity();
    }

    if (this.page_name == 'broker') {
      this.loginForm.get('email').clearValidators();
      this.loginForm.get('email').updateValueAndValidity();
      this.loginForm.get('recaptchaReactive').clearValidators();
      this.loginForm.get('recaptchaReactive').updateValueAndValidity();
    }

  }
  closePopup() {
    this.dialog.closeAll();
  }


  // resetpassword() {
  //   this.resetPassword.emit();
  // }

  showVar: boolean;
  toggleEyeImage() {
    this.showVar = !this.showVar;
  }
  register_(page) {
    this.register.emit(page);
  }
  submitLogin(val: any) {
    this.login(val);
    // this.ReCaptcha.execute('null').subscribe((token) => {
    //   this.reCaptcha_token = token;
    //   if (token) {
    //     this.login(val);
    //   } else {
    //     return;
    //   }
    // });
  }

  //login
  login(data: any) {
    this.captchaError = false;
    for (const key of Object.keys(this.loginForm.controls)) {
      if (this.loginForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        if(key == 'recaptchaReactive'){
          this.captchaError = true;
        }
        break;
      }
    }
    if (this.loginForm.invalid) {
      return;
    }
    let email_id;
    if (this.page_name == 'broker') {
      this.api.user_info.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res: any) => {
        if (res && res.user_id) {
          email_id = res.primary_email_id
        }
      });
    }
    let user_email = data.email ? data.email : email_id;
    let body = {
      user_name: user_email,
      user_password: data.password,
      keep_log_in: true,
    };
    this.loading = true;
    this.api.postUmsData('login', body).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (res: any) => {

        if (res.status == 200) {
          if(res.data.status != 1){
            this.alertService.warn("Your account is currently in-active");
            return;
          }
          let login_user = res.data;
          this.api.postBrokerData('broker-login', { "ums_id": login_user.user_id }).pipe(
            takeUntil(this.unsubscribe$)
          ).subscribe({
            next: (broker: any) => {
              if (broker.data && broker.data.length > 0) {
                login_user = { ...login_user, brokerList: broker.data };
                if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
                this.loading = false;
                this.setUserInfo(login_user);
              } else {
                this.loading = false;
                if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
                this.setUserInfo(login_user);
              }
            },

            error: (err) => {
              this.loading = false;
              if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
              this.setUserInfo(login_user);
            }
          })

        } else {
          this.loading = false;
          this.alertService.warn(res.message);
        }
      },
      error: (error) => {
        this.loading = false;
        this.alertService.warn(error.error);
      }
    });
  }
  // set data local
  setUserInfo(userdata: any) {
    let first_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].first_name : '';
    let last_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].last_name : '';

    if (userdata.user_details && userdata.user_details[this.api.language_id]) {
      first_name = userdata.user_details[this.api.language_id].first_name;
      last_name = userdata.user_details[this.api.language_id].last_name;
    }

    let user_info = {
      created_date: userdata.created_date,
      date_of_birth: userdata.date_of_birth,
      department_id: userdata.department_id,
      first_name: first_name,
      gender: userdata.gender,
      is_verified: userdata.is_verified,
      last_name: last_name,
      lat: userdata.lat,
      lon: userdata.lon,
      nationality: userdata.nationality,
      phone_number: userdata.phone_number,
      primary_email_id: userdata.primary_email_id,
      profile_image: userdata.profile_image,
      token: userdata.token,
      user_id: userdata.user_id,
    };
    if (userdata.brokerList) {
      user_info['broker_list'] = userdata.brokerList;
    }
    this.browserService.setLocalValue('user_id', userdata.user_id);
    this.browserService.setLocalValue('user_token', userdata.token);
    this.browserService.setLocalValue('user_info', JSON.stringify(user_info));
    this.browserService.setLocalValue('profile_image', userdata.profile_image);
    this.api.profile_image.next(userdata.profile_image);
    this.api.user_info.next(user_info);
    // this.share.getSavedProperties();
    this.share.selected_property.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(ele => {
      if (ele) {
        if (this.share.landing_obj) this.share.landing_obj.createCluster();
      }
    })
    this.dialogRef.close({ event: true });
  }

  ngOnDestroy(): void {
    this.isMobile;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

