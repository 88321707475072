import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BrowserService } from 'src/app/services/browser.service';
import { ApiServiceService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/core/components/alerts/alert.service';
import { ShareService } from 'src/app/services/share.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';


@Component({
  selector: 'success',
  template: `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;"><p>Please walt for redirect</p></div>`,
})
export class successComponent implements OnInit {

  page_name: string;
  slug: string='';
  unsubscribe$:Subject<void> = new Subject<void>();


  constructor(private http: HttpClient,
    private api: ApiServiceService,
    private route: Router,
    private activateRouter: ActivatedRoute,
    private translate: TranslateService,
    private alertService: AlertService,
    private browserService: BrowserService,
    private share: ShareService
    ) {


  }

  ngOnInit() {
    this.api.current_city_slug.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
    ).subscribe(slug=>{
      this.slug = slug;
    })
    this.doLogin();
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.doLogin();
    // }, 1000);
  }


  doLogin() {
    let app_id = '10';
    let place_id = this.api.city_id;

    let headers = new HttpHeaders();
    headers = headers.append('app-id', app_id);
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', String(place_id));

    let token = '';
    if (this.activateRouter.snapshot.queryParams.token) {
      token = this.activateRouter.snapshot.queryParams.token;
    }

    this.http
      .post(`${environment.umsUrl}google-login`, {
        auth_token: token,
      },
        { headers: headers }
      )
      .subscribe({
        next: (res: any) => {
          if (res && res?.data) {
            let login_user = res.data;
            this.api.postBrokerData('broker-login', { "ums_id": login_user.user_id }).subscribe({
              next: (broker: any) => {
                if (broker.data && broker.data.length > 0) {
                  login_user = { ...login_user, brokerList: broker.data };
                  if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
                  // this.loading = false;
                  this.setUserInfo(login_user);
                } else {
                  // this.loading = false;
                  if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
                  this.setUserInfo(login_user);
                }
              },
  
              error: (err) => {
                // this.loading = false;
                if (this.page_name != 'broker') this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
                this.setUserInfo(login_user);
              }
            })
  
          } else {
            // this.loading = false;
            this.alertService.warn(res.message);
          }
        },
        error: (error) => {
          let message = error?.error?.message?error.error.message:error.message;
          this.alertService.warn(message);
          setTimeout(()=>{
            this.route.navigateByUrl("/"+this.slug);
          }, 2000)
        }
      });
  }

  // set data local
  setUserInfo(userdata: any) {
    let first_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].first_name : '';
    let last_name = (userdata.user_details && userdata.user_details['1']) ? userdata.user_details['1'].last_name : '';

    if (userdata.user_details && userdata.user_details[this.api.language_id]) {
      first_name = userdata.user_details[this.api.language_id].first_name;
      last_name = userdata.user_details[this.api.language_id].last_name;
    }

    let user_info = {
      created_date: userdata.created_date,
      date_of_birth: userdata.date_of_birth,
      department_id: userdata.department_id,
      first_name: first_name,
      gender: userdata.gender,
      is_verified: userdata.is_verified,
      last_name: last_name,
      lat: userdata.lat,
      lon: userdata.lon,
      nationality: userdata.nationality,
      phone_number: userdata.phone_number,
      primary_email_id: userdata.primary_email_id,
      profile_image: userdata.profile_image,
      token: userdata.token,
      user_id: userdata.user_id,
    };
    if (userdata.brokerList) {
      user_info['broker_list'] = userdata.brokerList;
    }
    this.browserService.setLocalValue('user_id', userdata.user_id);
    this.browserService.setLocalValue('user_token', userdata.token);
    this.browserService.setLocalValue('user_info', JSON.stringify(user_info));
    this.browserService.setLocalValue('profile_image', userdata.profile_image);
    this.api.profile_image.next(userdata.profile_image);
    this.api.user_info.next(user_info);
    // this.share.getSavedProperties();
    this.share.selected_property.subscribe(ele => {
      if (ele) {
        if (this.share.landing_obj) this.share.landing_obj.createCluster();
      }
    })
    this.route.navigateByUrl("/"+this.slug);
    this.alertService.success(this.translate.instant('Home.user_loggedin_successfully'));
  }

}
