import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Alert, AlertType } from './alert';
import { AlertService } from './alert.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-alert',
    template: `
        <div class="alter_div">
            <div *ngFor="let alert of alerts" [class]="cssClass(alert)">
                <div class="icon_top">
                    <span></span>
                    <button class="close" [ngClass]="{'close_arb':currentLang == 'ar' }" (click)="removeAlert(alert)"><span>&times;</span></button>
                </div>
                <p class="alert-text">{{ alert.message }}</p>
            </div>
        </div>
    `,
    styleUrls: ['./alert.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    alerts: Alert[] = [];
    currentLang: string;
    constructor(private alertService: AlertService, @Inject(PLATFORM_ID) private platformId: any) {
        if (isPlatformBrowser(this.platformId)) {
            this.currentLang = localStorage.getItem('language');
        }
    }

    ngOnInit(): void {
        this.alertService.getAlert().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((alert: Alert) => {
            if (!alert) {
                this.alerts = [];
                return;
            }
            this.alerts.push(alert);
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => this.removeAlert(alert), 5000);
            }
        });
    }

    public removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    public cssClass(alert: Alert) {
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success left-border-success';
            case AlertType.Errors:
                return 'alert alert-danger left-border-danger';
            case AlertType.Info:
                return 'alert alert-info left-border-info';
            case AlertType.Warning:
                return 'alert alert-warning left-border-warning';
            default:
                return '';
        }
    }

    ngOnDestroy(): void {
        this.alerts;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
