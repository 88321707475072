<div class="mobile-header" id="mobile_head" [class.top-50]="isHomePage">
    <a [routerLink]="['/'+slug]"><img src="../../../../assets/icons/logo_.svg" alt="logo" class="mobile-logo" /></a>
    <div class="d-flex align-items-center">
        <div class="lang_mob lang"> <app-select-language></app-select-language></div>
        <div appClickOutside (clickOutside)="onOutsideClick($event)">
            <div *ngIf="page == 'em'"><img (click)="openECMenu()" src="{{profile_image}}" alt="user" onerror="this.src='assets/icons/user.svg'" class="user_icon" /></div> 
            <div *ngIf="page !== 'em'"><img (click)="openECMenu()" src="assets/icons/menu.svg" alt="user" class="" /></div> 
            <div id="mobile_menu" class="d-none" [class.isHome]="isHomePage">
                <app-mobile-menu [page]="page" [broker_list]="broker_list" (select_broker)="selectBrokerCom($event)" [slug]="slug"></app-mobile-menu>
             </div>
        </div>
        <div id="mobile_menu_overlay" class="d-none" [class.isHome]="isHomePage"></div>        
    </div>
</div>
<!-- <ng-container *ngIf="selectedSection == 'property'">
    <div class="propertyLevel_header" id="property_header" style="display: none;">
        <div class="down_arrow txt_position">
            <i class="fa fa-angle-down" (click)="arrowDown()"></i>
        </div>
        <div class="propert_heading">
            <h5>{{'Home.property_detail'| translate}}</h5>
        </div>
        <div class="sharing-btn_propertyLevel txt_position_Opp">
            <button (click)="share_property()">
                <img src="../../../../assets/icons/share_w.svg" alt="share" />
            </button>
            <button>
                <ng-container *ngIf="!is_saved"> 
                    <img src="../../../../assets/icons/save_w.svg" alt="save">
                </ng-container>
                <ng-container *ngIf="is_saved"> 
                    <img src="../../../../assets/icons/save_blue.svg" alt="save">
                </ng-container>
            </button>
        </div>
    </div>
    
</ng-container> -->
