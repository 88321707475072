// import {
//   ChangeDetectionStrategy,
//   Component,
//   ElementRef,
//   forwardRef,
//   QueryList,
//   ViewChildren,
// } from '@angular/core';
// import {
//   ControlValueAccessor,
//   FormArray,
//   FormControl,
//   NG_VALUE_ACCESSOR
// } from '@angular/forms';


// @Component({
//   selector: 'app-otp-input',
//   providers: [
//     {
//       useExisting: forwardRef(() => OtpInputComponent),
//       provide: NG_VALUE_ACCESSOR,
//       multi: true,
//     },
//   ],
//   templateUrl: './otp-input.component.html',
//   styleUrls: ['./otp-input.component.css'],
//   changeDetection: ChangeDetectionStrategy.OnPush,
// })
// export class OtpInputComponent implements ControlValueAccessor { 
//   private _isDisabled = false;

//   @ViewChildren('digit')
//   inputs: QueryList<ElementRef<HTMLInputElement>>;

//   digits: string[] = [null, null, null, null, null, null];

//   get disabled() {
//     return this._isDisabled;
//   }

//   _onTouched: () => void;
//   _onChange: (value: string | null) => void;

//   writeValue(obj: any): void {
//     if (typeof obj !== 'string') {
//       return;
//     }

//     if (obj.length !== this.digits.length) {
//       return;
//     }

//     this.digits = obj.split('');
//   }

//   registerOnChange(fn: any): void {
//     this._onChange = fn;
//   }

//   registerOnTouched(fn: any): void {
//     this._onTouched = fn;
//   }

//   setDisabledState?(isDisabled: boolean): void {
//     this._isDisabled = isDisabled;
//   }

//   onInput(index: number, $event: Event) {
//     const ev = $event as KeyboardEvent;
//     const digitInput = this.inputs.get(index).nativeElement;
//     const keyCode = ev.key;

//     //skip paste because it's handled in another method
//     if ((ev.metaKey || ev.ctrlKey) && (keyCode === 'v' || keyCode === 'V')) {
//       return;
//     } 

//     // const inputValue = digitInput.value.trim(); 
//     // if (inputValue.length > 1) {
//     //   // If the user pastes multiple characters in a single input box, distribute them
//     //   for (let i = 0; i < this.digits.length && i < inputValue.length; i++) {
//     //     this.digits[i] = inputValue[i];
//     //   }
//     // } else {
//     //   // Handle single character input
//     //   this.digits[index] = inputValue;
//     // }


//     // if (ev.metaKey && keyCode === 'v') {
//     //   return;
//     // }
   
//     if (['Backspace', 'Tab', 'Enter', 'Delete', 'ArrowRight', 'ArrowLeft'].indexOf(keyCode) >= 0) {
//       // backspace should delete the current value
//       // if (keyCode === 'Backspace' && digitInput.value) {        
//       //   const elFocus = this.inputs.get(index)?.nativeElement;
//       //   if (elFocus) {
//       //     elFocus.value = null;
//       //   }
//       //   this.emitChange();
//       //   elFocus?.focus();
//       // }
//       if(keyCode === 'Backspace' && !digitInput.value) {
//         const el = this.inputs.get(index -1)?.nativeElement;
//         if (el) {
//           el.value = null;
//         }
//         this.emitChange();
//         el?.focus();
//       }else if(keyCode === 'ArrowLeft'){
//         const el = this.inputs.get(index -1)?.nativeElement;
//         el?.focus();
//         setTimeout(function(){ el.selectionStart = el.selectionEnd = el.value.length; }, 0);
//       }else if(keyCode === 'ArrowRight'){
//         const el = this.inputs.get(index +1)?.nativeElement;
//         el?.focus();
//       }
//       return;
//     }

//     $event.preventDefault();

//     if (Number.isNaN(Number(keyCode))) {
//       return;
//     }

//     digitInput.value = keyCode;
//     this.emitChange();
//     this.inputs.get(index + 1)?.nativeElement.focus();
//   }

//   onPaste($event: ClipboardEvent) {
//     const numbers = ($event.clipboardData.getData('text') ?? '').slice(
//       0,
//       this.digits.length
//     );

//     if (/\d{6}/.test(numbers)) {
//       this.digits = numbers.split('');
//       this._onChange(numbers);
//     } else {
//       $event.preventDefault();
//     }
//   }

//   private emitChange() {
//     const value: string = this.inputs
//       .map((i) => i.nativeElement.value)
//       .join('');
//       if (value.length === 6) {
//         this._onChange(value);
//       }else{
//         this._onChange(null);
//       }
//   }
// }



import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-otp-input',
  providers: [
    {
      useExisting: forwardRef(() => OtpInputComponent),
      provide: NG_VALUE_ACCESSOR,
      multi: true,
    },
  ],
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpInputComponent implements ControlValueAccessor {
  private _isDisabled = false;

  @Output() submitevent = new EventEmitter();
  @ViewChildren('digit')
  inputs: QueryList<ElementRef<HTMLInputElement>>;

  digits: string[] = [null, null, null, null, null, null];

  get disabled() {
    return this._isDisabled;
  }

  _onTouched: () => void;
  _onChange: (value: string | null) => void;

  writeValue(obj: any): void {
    if (typeof obj !== 'string') {
      return;
    }

    if (obj.length !== this.digits.length) {
      return;
    }

    this.digits = obj.split('');
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  onInput(index: number, $event: Event) {
    const ev = $event as KeyboardEvent;
    const digitInput = this.inputs.get(index).nativeElement;
    const keyCode = ev.key;

    // Skip paste because it's handled in another method
    if ((ev.metaKey || ev.ctrlKey) && (keyCode === 'v' || keyCode === 'V')) {
      setTimeout(()=>{
        const el = this.inputs.get(index)?.nativeElement;
        el?.focus();
      }, 0)
      return;
    }
//     // if (ev.metaKey && keyCode === 'v') {
//     //   return;
//     // }

    if (['Backspace', 'Tab', 'Enter', 'Delete', 'ArrowRight', 'ArrowLeft'].indexOf(keyCode) >= 0) {
      if (keyCode === 'Backspace' && digitInput.value) {        
        const elFocus = this.inputs.get(index)?.nativeElement;
        if (elFocus) {
          elFocus.value = null;
        }
        this.emitChange();
        elFocus?.focus();
      }
      else if (keyCode === 'Backspace' && !digitInput.value) {
        const el = this.inputs.get(index - 1)?.nativeElement;
        if (el) {
          el.value = null;
        }
        this.emitChange();
        el?.focus();
      } else if (keyCode === 'ArrowLeft' && index > 0) {
        const el = this.inputs.get(index - 1)?.nativeElement;
        el?.focus();
        setTimeout(() => {
          el.selectionStart = el.selectionEnd = el.value.length;
        }, 0);
      } else if (keyCode === 'ArrowRight') {
        const el = this.inputs.get(index + 1)?.nativeElement;
        el?.focus();
      }
      else if ($event instanceof KeyboardEvent && $event.key === 'Enter') {
        this.submitevent.emit();
      }
    }

    $event.preventDefault();

    if (Number.isNaN(Number(keyCode))) {
  
      return;
    }

    digitInput.value = keyCode;
    this.emitChange();
    this.inputs.get(index + 1)?.nativeElement.focus();
  }
  onPaste($event: ClipboardEvent) {
    const numbers = ($event.clipboardData.getData('text') ?? '').replace(/\D/g, '').slice(0, this.digits.length);

    // Distribute pasted digits among the input fields
    for (let i = 0; i < this.digits.length && i < numbers.length; i++) {
      this.digits[i] = numbers[i];
      const inputElement = this.inputs.toArray()[i]?.nativeElement;
      if (inputElement) {
        inputElement.value = numbers[i];
      }
    }
    
    this._onChange(numbers);
    $event.preventDefault();
  }

  private emitChange() {
    const value: string = this.inputs
      .map((i) => i.nativeElement.value)
      .join('');
    if (value.length === 6) {
      this._onChange(value);
    } else {
      this._onChange(null);
    }
  }
}
