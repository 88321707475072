import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api.service';

@Component({
  selector: 'app-city-selection-dialog',
  templateUrl: './city-selection-dialog.component.html',
  styleUrls: ['./city-selection-dialog.component.scss']
})
export class CitySelectionDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  cities: any = [];
  city_id=null;
  isMobile: boolean;
  hideClose: boolean;
  hideClosebutton: boolean;
  constructor(
    private api: ApiServiceService,
    private dialogRef: MatDialogRef<CitySelectionDialogComponent>,
    
    @Inject(MAT_DIALOG_DATA) public parentData: any
    ) {
      this.hideClose = (this.parentData?.hideClose)? true:false;
      this.hideClosebutton = (this.parentData?.hideClosebutton)? true:false;
      this.isMobile = this.api.isMobile;
      this.city_id = this.api.city_id;
     }

     ngOnInit(): void {
      try {
         this.api.cities.pipe(
          takeUntil(this.unsubscribe$),
          distinctUntilChanged(),
        ).subscribe((data: any) => {   
          if(data){
            data.forEach((ele: any) => {
              if(ele.image == null || ele.image == undefined){
                ele.image = 'assets/city-icon/Others.svg';
              }
            });
            this.cities = data;
            this.cities.sort((a: any, b: any) => (a.place_name < b.place_name ? -1 : 1));
          } 
        });
      } catch (error) {
        // Handle error appropriately
      }
    }
    
  select_city(value:any){
    this.dialogRef.close(value);
  }
  close(){
    this.dialogRef.close(null);
  }
  ngOnDestroy(){
    this.cities;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
