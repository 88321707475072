import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-ipblock',
    template: `
        <div class="ip-block">
            <h3>{{'Home.action_blocked' | translate}}</h3>
            <p>{{meesage}}</p>
            <a>info@estater.com</a>
        </div>
    `,
    styleUrls: ['./ipblock.scss']
})

export class IpBlockComponent implements OnInit {
    public meesage = '';
    constructor(@Inject(MAT_DIALOG_DATA) public parentdata) {

    }

    ngOnInit(): void {
        this.meesage = this.parentdata.replace('info@estater.com', '')
    }
}
