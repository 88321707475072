
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { AlertService } from '../core/components/alerts/alert.service';
import { BlockData } from '../home/models/block';
import { DistrictData } from '../home/models/district';
import { PropertyData } from '../home/models/propertyData';
import { ApiServiceService } from './api.service';
import { BrowserService } from './browser.service';
import { CommonfunctionService } from './commonFunctionService';
import { EncryptDecryptService } from './encryptdecrypt.service';

@Injectable()
export class LoadDataService {
  public property_category = new BehaviorSubject<any>('');
  public property_type = new BehaviorSubject<any>('');
  public property_totalRecord = new BehaviorSubject<number>(0);
  public propertyListLoade =  new BehaviorSubject<boolean>(true);
  public initial_call: boolean = true;
  private count_uids = [];
  constructor(
    private browser: BrowserService,
    private api: ApiServiceService,
    private fnc: CommonfunctionService,
    private alertService: AlertService,
    private estaterSecure: EncryptDecryptService
  ) { }

  getPropertyCategories() {
    if (this.initial_call) {
      return new Promise((resolve, reject) => {
        this.api
          .getData(`settings?key=property_categories&type=property&status=1`)
          .subscribe({
            next: (res: any) => {
              if (res && res.data) {
                let categories = [];
                res.data.map((item) => {
                  categories.push(item['value']);
                });
                this.browser.setLocalValue(
                  'property_categories',
                  categories.toString()
                );
                this.initial_call = false;
                this.property_category.next(categories.toString());
                resolve(categories.toString());
              }
            },
            error: (err) => {
              this.initial_call = true;
              reject(err);
            }
          });
      });
    }
  }
  getPropertyTypes() {
    let url = `propertytypes?offset=0&status=1`;
    this.api.getData(url).subscribe((res: any) => {
      if (res && res.status == 200) {
        let {data} = res;
        data.forEach((element) => {
          if (element && element.property_type_name == 'house') {
            this.browser.setLocalValue(
              'property_house',
              JSON.stringify(element)
            );
            this.getPropertyTypeMaster(element.property_type_id);
          }
        });
      }
    });
  }

  getPropertyTypeMaster(prop_type_id?) {
    let url = `property_unit/masters/values?limit=1000&master_names=unit_types&offset=0&property_type_ids=${prop_type_id}&sort_asc=true&status=1`;
    this.api.getData(url).pipe(
      distinctUntilChanged(),
    ).subscribe((res: any) => {
      if (res) {
        this.browser.setLocalValue('house_villa', JSON.stringify(res));
      }
    });
  }
  
  //get properties
  getProperties(data: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          neigh_uid,
          block_uid,
          property_id,
          polygon,
          offset,
          limit,
          searchTerm,
          is_count,
        } = data;
        let params = {search:searchTerm}
        let url= `property-list`;
        if (property_id) {
          url = `property-single/${property_id}`;
          if (this.api.user_id) {
            url += `?user_id=${this.api.user_id}`;
          } else {
            url += `?session_id=${this.api.session_id}`;
          }
        } else {
          url += `?sort_asc=true&sort_by=property_uid`;
          url += `&limit=15`;
          url += `&is_count=${is_count?false:true}`;
          url += `&offset=${offset?offset:0}`;
          if (searchTerm) url += `&filter=${encodeURIComponent(JSON.stringify(params))}`;
          if (this.api.user_id) {
            url += `&user_id=${this.api.user_id}`;
          }
          if (polygon) {
            url += `&polygon=${polygon}`;
          }
          if (neigh_uid) {
            url += `&neigh_uid=${neigh_uid}`;
          }
          if (block_uid) {
            url += `&block_uid=${block_uid}`;
          }
        }
        let propertyList = [];
        this.api.getData(url).subscribe({
          next: async (res: any) => {
            if (res && res.status == 200) {
              if (property_id) {
                propertyList = [this.create_property_data(res.data[0])];
              } else {
                propertyList = this.createPropertyData(res.data);
                if(!is_count)this.property_totalRecord.next(res.totalRecord);
              }
              resolve(propertyList);
            } else {
              this.property_totalRecord.next(0);
              resolve([]);
            }
          },
          error: (err) => {
            this.property_totalRecord.next(0);
            reject(err);
          }
        });
      } catch (error) {
        this.property_totalRecord.next(0);
        reject(error);
      }
    });
  }

  //get properties point data
  getPropertiesPoint(data: any) {

    return new Promise(async (resolve, reject) => {
      let propertyList = [];
      try {
        const { neigh_uid, block_uid, polygon, zoomLevel } = data;
        let url = `properties-point?`;
        if (neigh_uid) { url += `neigh_uid=${neigh_uid}`; }
        if (block_uid) { url += `&block_uid=${block_uid}`; }
        if (polygon) { url += `&polygon=${polygon}`; }
        let params = {zoom_level:zoomLevel}
        if (zoomLevel) url += `&filter=${encodeURIComponent(JSON.stringify(params))}`;

        this.api.getData(url).subscribe({
          next: async (res: any) => {
            if (res.status == 200 && res.data?.length) {
              res.data.forEach((p) => {
                let arrayvalue = this.estaterSecure.decryptKey(p.ids).split('***');
                let lat = arrayvalue[0];
                let lon = arrayvalue[1];
                propertyList.push({
                  lat: lat,
                  lon: lon,
                  property_id: p.property_id,
                  legend: p.legend,
                  land_area: p.land_area,
                  img_url: p.img_url
                })
              })
            } else {
              propertyList = res;
            }
            resolve(propertyList);
          },
          error: (err) => {
            reject(propertyList);
          }
        });
      } catch (error) {
        reject(propertyList);
      }
    });
  }


  createPropertyData(res: any) {
    let propertyList: PropertyData[] = [];
    if (res && res?.length > 0) {
      res.map((p: any) => {
        propertyList.push(this.create_property_data(p));
      });
    }
    return propertyList;
  }
  // niegh geom
  getNeighGeom(neight_uid = null) {
    return new Promise((resolve, reject) => {
      let neght = null;
      let url = `neighbourhoods/geojson-details?table_name=neighbourhoods&limit=10000&offset=0`;
      if (neight_uid) url += `&uids=${neight_uid}`;
      let categories: any;
      if (this.browser.getLocalValue('property_categories')) {
        categories = this.browser.getLocalValue('property_categories');
      }
      url += `&sub_category=${categories}`;
      this.api.getData(url).subscribe({
        next: (res: any) => {
          if (res.data?.length) {
            neght = res.data;
            resolve(neght);
          } else {
            this.alertService.warn(res.message);
            reject(neght);
          }
        },
        error: (err) => {
          reject(neght);
        }
      });
    });
  }

  // block geom
  getBlocksGeom(selected_neigh) {
    return new Promise((resolve, reject) => {
      let blockList = null;
      let url = `blocks/geojson-details?table_name=blocks&limit=10000&offset=0&uids=${selected_neigh}`;
      let categories;
      if (this.browser.getLocalValue('property_categories')) {
        categories = this.browser.getLocalValue('property_categories');
      }
      url += `&sub_category=${categories}`;
      this.api.getData(url).subscribe({
        next: (res: any) => {
          if (res.data && res.status == 200) {
            blockList = res.data;
            resolve(blockList);
          } else {
            this.alertService.warn(res.message);
            resolve(blockList);
          }
        },
        error: (err) => {
          reject(blockList);
        }
      });
    });
  }

  // get neigh data by slug name
  getNeighDataBySlug(slug) {
    return new Promise((resolve, reject) => {
      let neigh_data = null;
      let url = `neighbourhoods/details?slug=${slug}&get_geom=false&get_media=true&get_centroid=true`;
      this.api.getData(url).subscribe({
        next: (res: any) => {
          if (res.data && res.data?.length) {
            neigh_data = res.data;
            resolve(neigh_data);
          } else {
            reject(neigh_data);
          }
        },
        error: (err) => {
          reject(neigh_data);
        }
      });
    });
  }

  // get neigh data by Neigh Uid
  getNeighData(neigh_uid) {
    return new Promise((resolve, reject) => {
      let neigh_data = null;
      let url = `neighbourhoods/details?neigh_uid=${neigh_uid}&get_geom=false&get_media=true&get_centroid=true`;
      this.api.getData(url).subscribe({
        next: (res: any) => {
          if (res.data && res.data?.length) {
            neigh_data = res.data;
            resolve(neigh_data);
          } else {
            reject(neigh_data);
          }
        },
        error: (err) => {
          reject(neigh_data);
        }
      });
    });
  }

  // get block data
  getBlockData(neigh_uid, block_uid) {
    return new Promise((resolve, reject) => {
      let block_data = null;
      let url = `blocks/details?block_uid=${block_uid}&neigh_uid=${neigh_uid}&get_geom=true&get_media=true&get_centroid=true`;
      this.api.getData(url).subscribe({
        next: (res: any) => {
          if (res.data && res.data?.length) {
            block_data = res.data;
            resolve(block_data);
          } else {
            reject(block_data);
          }
        },
        error: (err) => {
          reject(block_data);
        }
      });
    });
  }

  /// get property type
  async getPropertyType() {
    let list = [];
    if (this.browser.getLocalValue('property_type_list')) {
      let prt_list = JSON.parse(
        this.browser.getLocalValue('property_type_list')
      );
      prt_list.map((item) => {
        if (item.property_type_name == 'house') {
          item['default'] = true;
        } else {
          item['default'] = false;
        }
        list.push(item);
      });
      this.property_type.next(list);
    }
    try {
      let proptype = await this.getPropertyTypeList();
      if (proptype) {
        list = [];
        proptype['property_type_list'].map((item) => {
          if (item.property_type_name == 'house') {
            item['default'] = true;
          } else {
            item['default'] = false;
          }
          list.push(item);
        });
        this.property_type.next(list);
        this.browser.setLocalValue('property_type_list', JSON.stringify(list));
      }
    } catch (error) {
      //console.log(error);

    }
  }

  //get Property Type List

  getPropertyTypeList() {
    return new Promise((resolve, reject) => {
      try {
        let list = [];
        this.api
          .getData(
            'propertytypes?sort_by=property_type_name&order_by=sort_order&status=1'
          )
          .subscribe({
            next: (res: any) => {
              if (res.status == 500) {
                reject(list);
              } else {
                if (res && res.status == 200) {
                  let { data } = res;
                  data.map((type) => {
                    list.push({
                      property_type_id: type.property_type_id,
                      property_type_label: type.property_type_label[
                        this.api.language_id
                      ]
                        ? type.property_type_label[this.api.language_id]
                        : type.property_type_name,
                      property_type_name: type.property_type_name,
                      status: type.status,
                      updated_by: type.updated_by,
                    });
                  });
                  resolve({
                    property_type_list: list,
                  });
                }
              }
            },
            error: (err) => {
              reject(list);
            }
          });
      } catch (error) {
        //console.log(error);
      }
    });
  }

  getUnitTypes(master_name, ptype) {
    let list = [];
    return new Promise((resolve, reject) => {
      this.api
        .getData(
          `property_unit/masters/values?limit=100&status=1&property_type_ids=${ptype.property_type_id}&master_names=${master_name}`
        )
        .subscribe({
          next: (res: any) => {
            res.forEach((type) => {
              list.push({
                master_id: type.master_id,
                master_value_id: type.master_value_id,
                master_value_label: type.master_value_label[
                  this.api.language_id
                ]
                  ? type.master_value_label[this.api.language_id]
                  : type.master_value_name,
                master_value_name: type.master_value_name,
                parent_master_value_id: type.parent_master_value_id,
                parent_master_value_ids: type.parent_master_value_ids,
                property_type_id: type.property_type_id,
                property_type_name: ptype.property_type_name,
                status: type.status,
              });
            });
            resolve({
              unit_types_list: list,
            });
          },
          error: (err) => {
            //console.log(err);
          }
        });
    });
  }
  propertyCount: any = {};
  getPropertyCount(uid: string, total:number=0) {
    if (uid) {
      let prpertyCount = this.browser.getSessionStorage('property_count') ?JSON.parse(
        this.browser.getSessionStorage('property_count')
      ):null;
      let x = prpertyCount ? prpertyCount[uid] : 0;
      if (x > 0) {
        let prevData = JSON.parse(this.browser.getSessionStorage('property_count'));
        prevData[uid] = total;
        this.browser.setSessionStorage('property_count', JSON.stringify(prevData));
        this.propertyCount = prevData;
      } else {
        this.propertyCount = { ...this.propertyCount, ...{ [uid]: total } };
            this.browser.setSessionStorage(
              'property_count',
              JSON.stringify(this.propertyCount)
          );
      }
      let data = this.propertyCount;
      return data && data[uid] ? data[uid] : 0;
    }
  }

  create_property_data(p: any) {
    let lat, lon, address, property_uid;
    //Encryption-Decryption Logic implemented for Lat-Long-Address_Text-Property-UID on 08/02/2023
    if (p.ids || p.ids != null || p.ids != undefined) {
      let arrayvalue = this.estaterSecure.decryptKey(p.ids)?.split('***');
      lat = (arrayvalue?.length)? arrayvalue[0]:0;
      lon = (arrayvalue?.length)? arrayvalue[1]:0;
      // address = arrayvalue[2];
      // property_uid = arrayvalue[3];
    }

    let data = typeof p.data == 'string' ? JSON.parse(p.data) : p.data;
    let property_name = data && data.property_name ? data.property_name : property_uid;
    let images = data && data.images ? data.images : null;
    let img_url = data && data.images && data.images?.length ? data.images['0']['0'] : null;
    let corner = (p.parcel_data?.hasOwnProperty('corner')) ? p.parcel_data.corner : p.land_corner;
    let land_direction = (p.parcel_data?.hasOwnProperty('frontage')) ? p.parcel_data.frontage : p.land_direction;
    return new PropertyData({
      coords: { lng: lon, lat: lat },
      lat: lat,
      lon: lon,
      data: data,
      property_id: p.property_id,
      property_uid: p.property_uid,
      property_name: property_name,
      legend: p?.legend ? p.legend: '',
      images: images,
      img_url: img_url,
      property_address: p?.address_text ? p.address_text: '',
      parcel_id: p.parcel_id ? p.parcel_id : null,
      parcel_data: p.parcel_data ? p.parcel_data : null,
      corner: corner ? corner : 0,
      land_direction: land_direction ? (typeof land_direction == 'string')
        ? this.fnc.getFrontage(JSON.parse(land_direction))
        : this.fnc.getFrontage(land_direction)
        : 0,
      land_area:
        p.parcel_data && p.parcel_data.parcel_area
          ? p.parcel_data.parcel_area
          : p.land_area,
      set_back: this.fnc.getSetbackArea(p),
      merged_parcel: this.fnc.getMergedParcel(p),
      serarch_param: property_name + ' ' + p.property_uid + ' ' + address,
      is_saved: p.is_saved,
      saved_id: p.saved_id,
      owned_id: p.owned_id,
      tracked_id: p.tracked_id,
      preference_data: p.preference_data,
      direction: p.land_direction
        ? typeof p.land_direction == 'string'
          ? JSON.parse(p.land_direction)
          : p.land_direction
        : 0,
    });
  }

  create_neigh_data(neigh_data) {
    neigh_data.neigh_name = typeof neigh_data.neigh_name == 'string'
      ? JSON.parse(neigh_data.neigh_name)
      : neigh_data.neigh_name;

    let neigh_name =
      neigh_data &&
        neigh_data.neigh_name &&
        neigh_data.neigh_name[this.api.language_id] ? neigh_data.neigh_name[this.api.language_id] : neigh_data.neigh_name[1];
    let data = new DistrictData();
    data = {
      neigh_name: neigh_name,
      lat: neigh_data.lat ? neigh_data.lat : '',
      lon: neigh_data.lon ? neigh_data.lon : '',
      neigh_uid: neigh_data.neigh_uid ? neigh_data.neigh_uid : null,
      occupancy: neigh_data.occupancy ? neigh_data.occupancy : 0,
      shape_area: neigh_data.shape_area
        ? this.fnc.modifyInt({ value: neigh_data.shape_area, roundUp: true })
        : 0,
      neigh_id: neigh_data.neigh_id,
      slug: neigh_data.slug ? neigh_data.slug : null
    };
    return new DistrictData(data);
  }

  create_block_data(block_data) {
    block_data.block_name =
      typeof block_data.block_name == 'string'
        ? JSON.parse(block_data.block_name)
        : block_data.block_name;
    let block_name =
      block_data &&
        block_data.block_name &&
        block_data.block_name[this.api.language_id]
        ? block_data.block_name[this.api.language_id]
        : '';
    let data = {
      block_name: block_name ? block_name : '',
      block_uid: block_data.block_uid,
      lat: block_data.lat,
      lon: block_data.lon,
      neigh_uid: block_data.neigh_uid,
      occupancy: block_data.occupancy,
      shape_area: block_data.shape_area
        ? this.fnc.modifyInt({ value: block_data.shape_area, roundUp: true })
        : 0,
      block_id: block_data.block_id,
    };
    return new BlockData(data);
  }

  getAddress(own_address: any) {
    let address = '';
    if (own_address && own_address.address_text) {
      address =
        typeof own_address.address_text == 'string'
          ? JSON.parse(own_address.address_text)[this.api.language_id]
          : own_address.address_text[this.api.language_id];
      return address;
    } else if (own_address) {
      let lagid = this.api.language_id;
      if (this.api.language_id == 1) {
        if (own_address['house_no'])
          address += 'House No ' + own_address.house_no;
        if (own_address['building_name'])
          address +=
            ' ' +
            (own_address.building_name && own_address.building_name[lagid]
              ? own_address.building_name[lagid] + ', '
              : '');
        if (own_address['lane_name'])
          address +=
            '' +
            (own_address.lane_name && own_address.lane_name[lagid]
              ? own_address.lane_name[lagid] + ', '
              : '');
        if (own_address['street_name'])
          address +=
            '' +
            (own_address.street_name && own_address.street_name[lagid]
              ? own_address.street_name[lagid] + ', '
              : '');
        if (own_address['parcel_no'])
          address += 'Parcel ' + own_address.parcel_no + ', ';
        if (own_address['block_name'])
          address +=
            'Block ' +
            (own_address.block_name && own_address.block_name[lagid]
              ? own_address.block_name[lagid] + ', '
              : '');
        if (own_address['neigh_name'])
          address +=
            '' +
            (own_address.neigh_name && own_address.neigh_name[lagid]
              ? own_address.neigh_name[lagid]
              : '');
      } else if (this.api.language_id == 2) {
        if (own_address['house_no'])
          address += 'رقم المنزل ' + own_address.house_no;
        if (own_address['building_name'])
          address +=
            ' ' +
            (own_address.building_name && own_address.building_name[lagid]
              ? own_address.building_name[lagid] + ', '
              : '');
        if (own_address['lane_name'])
          address +=
            '' +
            (own_address.lane_name && own_address.lane_name[lagid]
              ? own_address.lane_name[lagid] + ', '
              : '');
        if (own_address['street_name'])
          address +=
            '' +
            (own_address.street_name && own_address.street_name[lagid]
              ? own_address.street_name[lagid] + ', '
              : '');
        if (own_address['parcel_no'])
          address += 'قطعة ' + own_address.parcel_no + ', ';
        if (own_address['block_name'])
          address +=
            'منع ' +
            (own_address.block_name && own_address.block_name[lagid]
              ? own_address.block_name[lagid] + ', '
              : '');
        if (own_address['neigh_name'])
          address +=
            '' +
            (own_address.neigh_name && own_address.neigh_name[lagid]
              ? own_address.neigh_name[lagid]
              : '');
      }
      return address;
    }
  }
}
