<ng-container>
    <app-alert></app-alert>
    <div class="position-relative"> <mat-progress-bar *ngIf="loading" style="position: absolute; top: 0;  width: 100%;height: 5px;"
        mode="indeterminate"></mat-progress-bar></div>
        <div class="broker_pass_heading" *ngIf="page_name == 'broker'">
            <div class="top-section mb-3">
                <img class="close" src="assets/icons/Cross_purple.svg" alt="close" (click)="closePopup()">
                <img src="assets/icons/reset_password.svg" alt="reset password">
                <p>{{'User.broker_password'| translate}}</p>
            </div>
        </div>
    <div class="heading_div " *ngIf="page_name != 'broker'">
        <h4 *ngIf="!isMobile">{{'User.Login' | translate}}</h4>
        <span class="new-logo" *ngIf="isMobile"><img src="../../../../../assets/icons/logo_.svg" alt="logo_"></span>
        <img class="close pointer close_align" (click)="closePopup()" src="assets/icons/Cross_purple.svg" alt="close" />
    </div>
    <form [formGroup]="loginForm" (ngSubmit)=submitLogin(loginForm.value) class="loginForm">
        <ng-container *ngIf="page_name != 'broker'">
            <div class="form-group">
                <div class="input-group _direction txt_position">
                    <span class="email-icon input-group-addon" [ngClass]="{'is-invalid': loginForm.get('email').invalid && loginForm.get('email').touched}"></span>
                    <input matInput class=" form-control" formControlName="email" type="email" required
                        placeholder="{{'User.Enter_your_Email' | translate}}"
                        [ngClass]="{'is-invalid': loginForm.get('email').invalid && loginForm.get('email').touched}" />
                </div>
            </div>
            <div class="form-group">
                <div class="input-group _direction txt_position">
                    <span class="pass-icon input-group-addon" [ngClass]="{'is-invalid': loginForm.get('password').invalid && loginForm.get('password').touched}"></span>
                    <input class="form-control txt_password" formControlName="password" type="{{ showVar ? 'text' : 'password'}}" required placeholder="{{'User.Enter_your_Password' | translate}}"
                    [ngClass]="{'is-invalid': loginForm.get('password').invalid && loginForm.get('password').touched}" />
                    <div class="eye_img">
                        <img alt="eye closed" src="{{ showVar ? 'assets/icons/eye_open_grey.svg' : 'assets/icons/eye_closed.svg'}}" (click)="toggleEyeImage()" class="{{ showVar ? 'open' : 'close'}}" />
                    </div>
                </div>
            </div>
            <div class="form-group check_box _direction txt_position">
                <span>
                    <label class="switch switch-3">
                        <input type="checkbox" formControlName="remember_me" />
                        <span class="slider round"></span>
                    </label>
                    <!-- <input type="checkbox" formControlName="remember_me" />  -->
                    <span class="checkbox"> {{'User.Stay_sign_in' |
                        translate}}</span>
                </span>
                <span class="forgot pointer" *ngIf="page_name == 'login'" (click)="register_('forgotpassword')">{{'User.Forget_password' | translate}}</span>
            </div>
            <div class="form-group txt_position" *ngIf="recaptchaActive">
                <re-captcha id="recaptcha_1" formControlName="recaptchaReactive"></re-captcha>
                <span class="d-none text-danger" *ngIf="captchaError">{{'Form.captcha_required' | translate}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="page_name == 'broker'">
            <div class="form-group">
                <div class="input-group _direction txt_position">
                    <span class="pass-icon input-group-addon" [ngClass]="{'is-invalid': loginForm.get('password').invalid && loginForm.get('password').touched}"></span>
                    <input class="form-control txt_password" formControlName="password" type="{{ showVar ? 'text' : 'password'}}" required placeholder="{{'User.Enter_your_Password' | translate}}"
                    [ngClass]="{'is-invalid': loginForm.get('password').invalid && loginForm.get('password').touched}" />
                    <div class="eye_img">
                        <img alt="eye closed" src="{{ showVar ? 'assets/icons/eye_open_grey.svg' : 'assets/icons/eye_closed.svg'}}" (click)="toggleEyeImage()" class="{{ showVar ? 'open' : 'close'}}" />
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="form-group text-center mt-3">
            <button class="submit-buttton btn" type="submit" [disabled]="loading" ><span *ngIf="page_name != 'broker'">{{'User.Login' | translate}}</span><span *ngIf="page_name == 'broker'">{{'User.Submit' | translate}}</span></button>
        </div>
    </form>
    <ng-container *ngIf="page_name != 'broker'">
        <app-social-login (close)="closePopup()"></app-social-login>
        <div class="bottom_login text-center">
            <p class="mb-1">{{'User.Dont_have_account' | translate}} <span (click)="register_('register')">{{'User.Register_here' |
                    translate}}</span></p>
        </div>
    </ng-container>
</ng-container>
