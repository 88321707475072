export class PropertyData{
    coords: any;
    lat: number;
    lon: number;
    data: any;
    property_id: number;
    property_uid: string = '';
    property_name: string = '';
    own_address: any;
    images: any;
    img_url: string = '';
    property_address: any;
    block_name: string = '';
    block_uid: string = '';
    neigh_name: string = '';
    neigh_uid: string = '';
    parcel_id: number;
    parcel_data: any;
    corner: number;
    land_direction: number
    land_area: number;
    set_back: any;
    merged_parcel: any;
    serarch_param: string = '';
    is_saved: boolean;
    saved_id: number;
    direction: any;

    constructor(Values:any={}){
        Object.assign(this, Values);
    }
}