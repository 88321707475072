import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api.service';
import { BrowserService } from 'src/app/services/browser.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
  selectedSection:string='';
  langSubscriber: Subscription;
  selected_lang: any = this.browser.getLocalValue(
    'LOCALIZE_DEFAULT_LANGUAGE'
  ); 
  public slug = '';
  public urlLang = '';
  @Input() page;
  @Input() broker_list:any[]=[];
  @Input() isHomePage:boolean;
  @Output() selectBroker = new EventEmitter;
  private unsubscribe$:Subject<void>=new Subject<void>();
  profile_image:string='assets/icons/user.svg';
  constructor(@Inject(PLATFORM_ID) private platformId: any, private share: ShareService, private api:ApiServiceService, public dialog: MatDialog,  private browser:BrowserService,
    private route: Router) {
      this.api.profile_image.subscribe(img=>{
        if(img){
          this.profile_image = img;
        }else{
          this.profile_image='assets/icons/user.svg';
        } 
      })
      this.urlLang=(this.selected_lang=='ar'?'ar':'')
     }

  ngOnInit(): void {
    this.api.current_city_slug.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(slug=>{
      this.slug = slug;
    })
    this.share.selected_section.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data:string)=>{
      this.selectedSection = data;
    })
    this.api.lang.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((lang) => {
      this.selected_lang = lang;
      this.urlLang=(this.selected_lang=='ar'?'ar':'')
    });
  }
  
 
  /**
   * Select broker event pass to parent component 
   */
  selectBrokerCom(broker){
    this.selectBroker.emit(broker)
  }
  goToDashboard() {
    // let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}/dashboard/mobile-dashboard`;
    // this.route.navigateByUrl(path);
  }

  openECMenu(){
    let moiblemenuid = this.browser.getElements('ById','mobile_menu')
    let moibleoverlay = this.browser.getElements('ById','mobile_menu_overlay')
    
    if(moiblemenuid && moiblemenuid.classList.contains('d-none')){
      moiblemenuid.classList.remove('d-none');
      moibleoverlay.classList.remove('d-none');
    }else{
      moiblemenuid.classList.add('d-none');
      moibleoverlay.classList.add('d-none');
    }
    // let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}/dashboard/mobile-dashboard?page=ec`;
    // this.route.navigateByUrl(path);
  }

  /**
   * Close side bar menu on out side click
   * @param event 
   */
  public onOutsideClick(event: any): void {
    let moiblemenuid = this.browser.getElements('ById','mobile_menu');
    let moibleoverlay = this.browser.getElements('ById','mobile_menu_overlay');
    moiblemenuid.classList.add('d-none');
    moibleoverlay.classList.add('d-none');
  }
  // arrowDown(){
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.scrollTo({
  //       top: 120, 
  //       behavior: 'smooth'
  //     });
  //   }
  // }

  ngOnDestroy(): void {    
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
