<ng-container *ngIf="mobile">
    <ng-container *ngIf="!user_id">
        <!-- <div class="register-section"  *ngIf="page != 'ec'" [class.px-4]="page == 'ec'" [class.py-3]="page == 'ec'"> -->
        <div class="register-section"  [class.px-4]="page == 'ec'" [class.py-3]="page == 'ec'">
            <div class="cross-btn" >
                <div></div>
                <!-- <a (click)="close()"> <img src="../../../assets/icons/cross.svg" alt=""></a> -->
                <h3>{{'User.create_an_account' | translate}}</h3>
                <div></div>
            </div>
            <div class="btn-section">
                <button class="login" (click)="openDialog('login')">{{'User.Login' | translate}}</button>
                <button class="reg" (click)="openDialog('register')">{{'User.Register' | translate}}</button>
            </div>
        </div>
    </ng-container>  
    <ng-container *ngIf="user_id">
        <div class="top-section" [class.px-4]="page == 'ec'" [class.py-3]="page == 'ec'">
            <div class="user-section">
                <img src="{{profile_image}}" alt="profile image" onerror="this.src='assets/icons/user.svg'">
                <p> {{'dashboard.hello' | translate}}, <span>{{fullname}}</span></p>
            </div>
            <div class="edit-profile">
                <a [routerLink]="['/dashboard/settings/profile' | localize]">{{'dashboard.edit_profile' | translate}}</a>
                <!-- <button (click)="logout()" class="logout">{{'User.Logout' | translate}}</button> -->
                <app-city-dialog-button [state]="true"></app-city-dialog-button>
            </div>
            
        </div>
        <div class="middel-section">
            <div class="w-100">
                <cdk-accordion>
                    <cdk-accordion-item
                        #accordionItem="cdkAccordionItem"
                        tabindex="0"
                        [attr.id]="'accordion-header-' + 0"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-' + 0">
                        <div class="routlist" (click)="accordionItem.toggle()"  [class.bg-color]="accordionItem.expanded">
                            <div>
                                <!-- <button class="icon"><img src="../../../assets/icons/{{item.icon}}" alt="flag" class="icon_flag" /></button> -->
                                <span class="text_list">{{'User.Profile' | translate}}</span>
                            </div>
                            <img [class.revers-img]="accordionItem.expanded" src="assets/icons/down-arrow.svg" alt="" width="10">
                        </div>
                        <div
                            [class.bg-color]="accordionItem.expanded" 
                            role="region"
                            [style.display]="accordionItem.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + 0"
                            [attr.aria-labelledby]="'accordion-header-' + 0">
                            <!-- <a class="routlist sublist" (click)="rout('/dashboard/settings/profile')">
                                <button class="icon"><img src="../../../assets/icons/map.svg" alt="map" class="icon_map"></button>
                                    <span class="text_list">{{'dashboard.edit_profile' | translate}}</span>
                            </a> -->
                            <a class="routlist sublist" [routerLink]="['/dashboard/my-activities' | localize]">
                                <!-- <button class="icon"><img src="../../../assets/icons/map.svg" alt="map" class="icon_map"></button> -->
                                    <span class="text_list">{{'Home.my_activities'| translate}}</span>
                            </a>
                            <a class="routlist sublist" [routerLink]="['/dashboard/save-property' | localize]">
                                <!-- <button class="icon"><img src="../../../assets/icons/home.svg" alt="home" class="icon_home"></button> -->
                                    <span class="text_list">{{'Home.saved_properties' | translate}}</span>
                            </a>
                            <!-- <a class="routlist sublist" (click)="rout('/dashboard/notification')">
                                <button class="icon"><img src="../../../assets/icons/notification.svg" alt="notification" class="icon_notification"></button>
                                    <span class="text_list">{{'Home.notifications'|translate}}</span>
                            </a> -->
                            <a class="routlist sublist" [routerLink]="['/dashboard/help-center' | localize]">
                                <div>
                                    <!-- <button class="icon"><img src="../../../assets/icons/support.svg" alt="" class="icon_support"></button> -->
                                    <span class="text_list">{{'Home.help_center' | translate}}</span></div>
                            </a>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div class="w-100 txt_position">
                <cdk-accordion>
                    <cdk-accordion-item
                    *ngFor="let item of broker_list; let index = index;"
                    #accordionItem="cdkAccordionItem"
                    role="button"
                    tabindex="0"
                    [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div class="routlist" (click)="accordionItem.toggle()"  [class.bg-color]="accordionItem.expanded">
                        <div>
                            <!-- <button class="icon"><img src="../../../assets/icons/{{item.icon}}" alt="flag" class="icon_flag" /></button> -->
                            <span class="text_list">{{'User.Company' | translate}}</span>
                        </div>
                        <img [class.revers-img]="accordionItem.expanded" src="assets/icons/down-arrow.svg" alt="down-arrow" width="10">
                    </div>
                    <div
                        class="color-111"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <ng-container *ngIf="item.brokers">
                            <a class="routlist sublist" *ngFor="let broker of item.brokers" (click)="selectBroker(broker)">
                                <div>
                                    <!-- <button class="icon"><img src="../../../assets/icons/support.svg" alt="" class="icon_support"></button> -->
                                    <span class="text_list broker_name_ellipsis">{{broker.company_name[this.api.language_id]?broker.company_name[this.api.language_id]:broker.company_name[1]}}</span> <span class="text_list">| {{ broker.user_type_name }}</span></div>
                            </a>
                        </ng-container>
                    </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
        </div>
    </ng-container>

    <div class="middel-section txt_position">
        <div class="w-100">
            <a class="routlist" *ngIf="!user_id" (click)="cityselect()">
                <div>
                    <!-- <button class="icon"><img src="../../../assets/icons/feedback.svg" alt="feedback" class="icon_feedback"></button> -->
                    <span class="text_list">{{'Home.city' | translate}}</span></div>
            </a>
            <cdk-accordion>
                <cdk-accordion-item
                    *ngFor="let item of menu_list; let index = index;"
                    #accordionItem="cdkAccordionItem"
                    tabindex="0"
                    [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div class="routlist" (click)="accordionItem.toggle()"  [class.bg-color]="accordionItem.expanded">
                        <div>
                            <!-- <button class="icon"><img src="../../../assets/icons/{{item.icon}}" alt="flag" class="icon_flag" /></button> -->
                            <span class="text_list"> {{item.label | translate}}</span>
                        </div>
                        <img [class.revers-img]="accordionItem.expanded" src="assets/icons/down-arrow.svg" alt="down-arrow" width="10">
                    </div>
                    <div
                        [class.bg-color]="accordionItem.expanded" 
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <ng-container *ngIf="item.sub_menu_list">
                                <ng-container *ngFor="let submenu of item.sub_menu_list">
                                    <!--  -->
                                    <ng-container *ngIf="submenu.child_data; else subMenu">
                                        <div class="sub_child">
                                            <label for=""><strong>{{submenu.page_name}} </strong></label>
                                            <a class="routlist sublist" *ngFor="let submenuchild of submenu.child_data" (click)="rout(submenuchild.slug, submenu?.target, submenu?.settab, submenu.isCity, true)">
                                                <span class="text_list" style=" text-transform: capitalize;">{{'city.explore'|translate}} {{submenuchild.place_name}} </span>
                                            </a>
                                        </div>
                                    </ng-container>
                                    <!--  -->
                                    <ng-template #subMenu>
                                        <a class="routlist sublist"  (click)="rout(submenu.page_link, submenu?.target, submenu?.settab, submenu.isCity)">
                                            <span class="text_list" style=" text-transform: capitalize;">{{submenu.page_name | translate }}
                                                <p *ngIf="submenu.sub_title">{{submenu.sub_title | translate }}</p>
                                            </span>
                                        
                                        </a>
                                    </ng-template>
                                </ng-container>
                        </ng-container>                   
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
        </div>
       
        <a class="routlist" (click)="rout('/feedback')">
            <div>
                <!-- <button class="icon"><img src="../../../assets/icons/feedback.svg" alt="feedback" class="icon_feedback"></button> -->
                <span class="text_list">{{'Home.feedback' | translate}}</span></div>
        </a>
        <a *ngIf="user_id" class="routlist"(click)="logout()">
            <div>
                <!-- <button class="icon"><img src="../../../assets/icons/logout.svg" alt="logout" class="icon_logout"></button> -->
                <span class="text_list">{{'User.Logout'| translate}}</span></div>
        </a>
    </div>
    <div class="last-section txt_position">
        <ul>
            <li class="list-item" (click)="resetMenu()">                                
                <a [routerLink]="['/'+slug+'/terms-of-use'| localize]">{{'landing_page.footer_termsofuse'|translate}}</a>
            </li>
            <li class="list-item" (click)="resetMenu()">                                
                    <a [routerLink]="['/'+slug+'/site-map'| localize]">{{'landing_page.footer_siteMap'|translate}}</a>
            </li>
            <li class="list-item" (click)="resetMenu()">                                
                    <a [routerLink]="['/'+slug+'/api'| localize]">{{'landing_page.public_api'|translate}}</a>
            </li>
            <li class="list-item" (click)="resetMenu()">                                
                <a [routerLink]="['/'+slug+'/malls'| localize]">{{'landing_page.malls'|translate}}</a>
            </li>
            <li class="list-item" (click)="resetMenu()">                                
                <a [routerLink]="['/'+slug+'/broker-list'| localize]">{{'landing_page.brokers'|translate}}</a>
            </li>
        </ul>
        <div class="social-icon">
            <ul>
                <li><a href="https://www.facebook.com/estater/" target="_blank"><img src="assets/social_icon/Facebook.svg" alt="Facebook"></a></li>
                <li><a href="https://twitter.com/Estater_bits" target="_blank"><img src="assets/social_icon/Twitter.svg" alt="Twitter"></a></li>
                <li><a href="https://www.linkedin.com/company/estater/" target="_blank"><img src="assets/social_icon/Linkdin.svg" alt=""></a></li>
                <li><a href="https://instagram.com/estater_bits" target="_blank"><img src="assets/social_icon/Instagram.svg" alt="Instagram"></a></li>
            </ul>
            <p>{{current_year}} {{'dashboard.estate_all_rights_reserve' | translate}}</p>
        </div>
        
    </div>
</ng-container>