import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackComponent } from './home/component/feedback/feedback.component';
import { MetaGuard } from './services/meta-guard.service';
import { successComponent } from './success/success.component';
// import { MetaGuard } from '@ngx-meta/core';
import { Page404Component } from './page-404';
import { Page500Component } from './error-page500';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./ec/ec-home/home/home.module').then((h) => h.ECHomeModule),
    data: {
      discriminantPathKey: 'ECHOME'
    }
  },
  {
    path: ':cityid/estater-meter-gis',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./home/home.module').then((h) => h.EMHomeModule),
    data: {
      discriminantPathKey: 'EMHOME'
    }
  },
  {
    path: 'dashboard',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
    data: {
      discriminantPathKey: 'DASHBOARD',
      meta: {
        title: 'meta-data.emLanding_title',
        description: 'meta-data.emLanding_desc',
      }
    }
  },
  {
    path: ':cityid/success',
    component: successComponent
  },
  // { path: 'terms', component: TermsComponent },
  // { path: 'privacy', component: PrivacyComponent },
  // { path: 'contact', component: ContactUsComponent },
  { path: ':cityid/feedback', component: FeedbackComponent },
  {
    path: 'error',
    component: Page500Component
  },
  {
    path: '**',
    component: Page404Component
  },
  // { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled', scrollPositionRestoration: 'disabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { } 
