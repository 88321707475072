import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ApiServiceService } from './services/api.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ShareService } from './services/share.service';
import { BrowserService } from './services/browser.service';
import { CommonfunctionService } from './services/commonFunctionService';
import { WINDOW } from './window.providers';
import { Meta } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
export let IsPageReloading = false;

// declare const gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
pageReloading: boolean = false;
  isServer: boolean;
  isBrowser: boolean;
  mobile: boolean;
  url:string;
  private unsubscribe$:Subject<void>=new Subject<void>();

constructor(private meata: Meta, @Inject(WINDOW) private window: Window, private fnc:CommonfunctionService,private browser:BrowserService ,private share:ShareService, private router: Router, private api: ApiServiceService,private lang:LocalizeRouterService, @Inject(PLATFORM_ID) private platformId: any){

  this.getHostname()
  this.mobile = this.api.isMobile;
  let path = this.browser.getPathName();
  let preference_id = this.fnc.getParameterByName('preference');
  if(preference_id){
    let pid = this.fnc.getParameterByName('pid');
    let valutiondata={
      pid:pid
    }
      
    this.share.setPreference(valutiondata, false, false, preference_id);
  }
  let url = path ? path.split('/')[1]: null;
  if(!this.mobile && !url?.includes('dashboard')){
  if(url && (url.includes("-ar") || url == "ar")){
    this.lang.settings.alwaysSetPrefix=false;
    this.api.changeLanguage("ar");
    this.api.setdefaultlang("ar");
   } else {
    this.lang.settings.alwaysSetPrefix=false;
    // this.lang.changeLanguage('en')
    this.api.changeLanguage("en");
    this.api.setdefaultlang("en");
   }
  }

    router.events.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((event) => {
      let arr_url=[];
        if (event instanceof NavigationStart) {
          this.share.isValuationModalOpen.next(false)
          this.pageReloading = !router.navigated;
          IsPageReloading = true;
          if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0)
          }
          arr_url=event.url.split('/');
          if(this.mobile){
            if(event.url.includes("dashboard")){
              return;
            }
          }
          if(arr_url[1].includes('-ar') || arr_url[1] == 'ar'){
            this.api.changeLanguage("ar");
            this.api.setdefaultlang("ar");
          }else{
            this.api.changeLanguage("en");
            this.api.setdefaultlang("en");
          }
        }

    });

    this.isServer = isPlatformServer(this.platformId);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  getHostname() : void {
    if(!this.window.location.hostname.includes('estater.com')){
      this.meata.updateTag({name :"robots", content:"noindex"})
    };
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
