<div class="header_top">
    <div class="row">
        <div class="col-md-12">
            <div class="topbar">
                <span class="d-flex">
                    <a href="/">
                        <img class="logo" src="assets/icons/logo.svg" alt="estater" />
                    </a>
                </span>
                <div tourAnchor="account" (click)="openDialog()" class="user_icon"><img class="user-icon" src="{{profile_image}}"
                        alt="profile_image" onerror="this.src='assets/icons/user.svg'" /></div>
            </div>
        </div>
    </div>
</div>

<!-- Nav End Here -->
<div class="main_container txt_position">
    <section class="case-study-header">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>{{'privacy.privacy_policy' | translate}}</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container">
            <div class="row p-3">
                <p class="textp">{{'privacy.privacy_policy_p1' | translate}}</p>
                <p class="textp">{{'privacy.privacy_policy_p2' | translate}}</p>
                <ol class="main_ol">
                    <li>
                        <p class="main-heading">{{'privacy.info_collected' | translate}}</p>
                        <p class="textp">{{'privacy.info_collected_p1' | translate}}</p>
                        <ol>
                            <li>
                                <p class="main-heading">{{'privacy.info_provided' | translate}}</p>
                                <p class="textp">{{'privacy.info_provided_p1' | translate}}</p>
                                <p class="textp">{{'privacy.info_provided_p2' | translate}}</p>

                            <li>
                                <p class="main-heading">{{'privacy.tracking_technologies' | translate}}</p>
                                <p class="textp">{{'privacy.tracking_technologies_p1' | translate}}</p>
                                <p class="textp">{{'privacy.tracking_technologies_p2' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.third_party_tracking' | translate}}</p>
                                <p class="textp">{{'privacy.third_party_tracking_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.mobile_info' | translate}}</p>
                                <p class="textp">{{'privacy.mobile_info_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.location_data' | translate}}</p>
                                <p class="textp">{{'privacy.location_data_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.usage_log' | translate}}</p>
                                <p class="textp">{{'privacy.usage_log_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.call_text' | translate}}</p>
                                <p class="textp">{{'privacy.call_text_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.public_content' | translate}}</p>
                                <p class="textp">{{'privacy.public_content_p1' | translate}}</p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.social_networks' | translate}}</p>
                                <p class="textp">{{'privacy.social_networks_p1' | translate}}</p>
                                <p class="textp">{{'privacy.social_networks_p2' | translate}}</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.use_info' | translate}}</p>
                        <p class="textp">{{'privacy.use_info_p1' | translate}}</p>
                        <ol class="final_inner_list">
                            <li><p class="textp">{{'privacy.use_info_1' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_2' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_3' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_4' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_5' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_6' | translate}}</p>
                            </li>
                            <li><p class="textp">{{'privacy.use_info_7' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_8' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_9' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_10' | translate}}</p></li>
                            <li><p class="textp">{{'privacy.use_info_11' | translate}}</p></li>
                        </ol>
                        <br />
                        <p class="textp">
                            <b>{{'privacy.email_head' | translate}}</b> {{'privacy.email_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.disclose_info' | translate}}</p>
                        <p class="textp">{{'privacy.disclose_info_p1' | translate}}</p>
                        <ol>
                            <li>
                                <p class="main-heading">{{'privacy.consent' | translate}}</p>
                                <p class="textp">{{'privacy.consent_p1' | translate}}<span></span></p>
                            </li>

                            <li>
                                <p class="main-heading">{{'privacy.service_provider' | translate}}</p>
                                <p class="textp"><span>{{'privacy.service_provider_p1' | translate}}</span></p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.partners' | translate}}</p>

                                <p class="textp"><span>{{'privacy.partners_p1' | translate}}</span></p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.legal_protection' | translate}}</p>

                                <p class="textp"><span>{{'privacy.legal_protection_p1' | translate}}</span></p>
                                <p class="textp"><span>{{'privacy.legal_protection_p2' | translate}} </span></p>
                                <p class="textp"><span>{{'privacy.legal_protection_p3' | translate}}</span></p>
                                <p class="textp"><span>{{'privacy.legal_protection_p4' | translate}}</span></p>
                                <p class="textp"><span>{{'privacy.legal_protection_p5' | translate}} </span>
                                </p>
                                <p class="textp"><span>{{'privacy.legal_protection_p6' | translate}}</span></p>
                            </li>
                            <li>
                                <p class="main-heading">{{'privacy.business_transfer' | translate}}</p>
                                <p class="textp"><span>{{'privacy.business_transfer_p1' | translate}}</span></p>
                                <p class="textp"><span>{{'privacy.business_transfer_p2' | translate}}</span></p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.interest_ad' | translate}}</p>
                        <p class="textp">{{'privacy.interest_ad_p1' | translate}}</p>
                        <p class="textp">{{'privacy.interest_ad_p2' | translate}}</p>
                        <p class="textp">{{'privacy.interest_ad_p3' | translate}}</p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.fifth_point' | translate}}</p>
                        <p class="textp">{{'privacy.fifth_point_p1' | translate}}</p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.sixth_point' | translate}}</p>
                        <p class="textp">{{'privacy.sixth_point_p1' | translate}}
                        </p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.seventh_point' | translate}}</p>
                        <p class="textp">{{'privacy.seventh_point_p1' | translate}}</p>
                        <p class="textp">{{'privacy.seventh_point_p2' | translate}}</p>
                        <p class="textp">{{'privacy.seventh_point_p3' | translate}}</p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.eighth_point' | translate}}</p>
                        <p class="textp">{{'privacy.eighth_point_p1' | translate}}</p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.nineth_point' | translate}}</p>
                        <p class="textp">{{'privacy.nineth_point_p1' | translate}}<a
                                href="mailto:info@estater.com">info@estater.com</a></p>
                    </li>
                    <li>
                        <p class="main-heading">{{'privacy.tenth_point' | translate}}</p>
                        <p class="textp">{{'privacy.tenth_point_p1' | translate}}
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </section>
</div>
<ng-container *ngIf="!mobile">
    <app-footer></app-footer>
</ng-container>