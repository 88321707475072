<div>
    <app-header *ngIf="isMobile"></app-header>
    <div class="feedback_mob" *ngIf="isMobile" dir="ltr">
        <h4>{{ 'feedback.feedback' | translate }}</h4>
    </div>
    <div class="feedback header_feedback" *ngIf="!isMobile">
        <img class="closePopupBtn" (click)="closebackeedPopup()" src="assets/icons/cross_w.svg" alt="close">
        <h4>{{ 'feedback.feedback' | translate }}</h4>
    </div>
</div>
<div>
    <div class="text-center thank-you">
        <h5>{{ 'feedback.thank_you' | translate }}</h5>
        <p>{{ 'feedback.for_using' | translate }}</p>
    </div>
    <div class="form-area">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
            
            <input class="form-control" id="name" formControlName="name" type="text" placeholder="{{ 'feedback.enter_name' | translate }}" 
            [ngClass]="{'is-invalid': registerForm.get('name').invalid && (registerForm.get('name').touched || isSubmited)}">
            <mat-error *ngIf="registerForm.get('name').hasError('required') && (registerForm.get('name').touched || isSubmited)">
                {{ 'feedback.name_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('name').hasError('maxlength') && (registerForm.get('name').touched || isSubmited)">
                {{'submit_query.Name_60_character' | translate}}
            </mat-error>
            <mat-error *ngIf="registerForm.get('name').hasError('minlength') && (registerForm.get('name').touched || isSubmited)">
                <span style="color: red">{{'submit_query.Name_2_character'| translate}}</span>
            </mat-error>
            <input class="form-control" id="email" formControlName="email" type="email" placeholder="{{ 'feedback.enter_email' | translate }}" 
            [ngClass]="{'is-invalid': registerForm.get('email').invalid && (registerForm.get('email').touched || isSubmited)}">
            <mat-error *ngIf="registerForm.get('email').hasError('required') && (registerForm.get('email').touched || isSubmited)">
                {{ 'feedback.email_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('email').hasError('pattern') && (registerForm.get('email').touched || isSubmited)">
                {{'submit_query.Email_must_valid_email_address'| translate}}e
            </mat-error>

            <textarea formControlName="feedback_message" class="form-control" id="feedback_message" placeholder="{{ 'feedback.share_a' | translate }}" 
                [ngClass]="{'is-invalid': registerForm.get('feedback_message').invalid && (registerForm.get('feedback_message').touched || isSubmited)}">
            </textarea>
            <mat-error *ngIf="registerForm.get('feedback_message').hasError('required') && (registerForm.get('feedback_message').touched || isSubmited)">
                {{ 'feedback.message_is_required' | translate }}
            </mat-error>
              <mat-error *ngIf="registerForm.get('feedback_message').hasError('minlength') && (registerForm.get('feedback_message').touched || isSubmited)">
                {{'submit_query.Name_2_character'| translate}}
              </mat-error>
              <mat-error *ngIf="registerForm.get('feedback_message').hasError('maxlength') && (registerForm.get('feedback_message').touched || isSubmited)">
                {{'submit_query.Description_250_character' | translate}}
              </mat-error>
            <div class="mt-3 mb-3 text-center">
                <button [disabled]="registerForm.invalid || loader" type="submit" value="send" class="btn send-btn">
                    <span *ngIf="!loader">{{ 'feedback.send' | translate }}</span>
                    <span *ngIf="loader">{{ 'feedback.sending' | translate }}</span>
                </button>
            </div>
        </form>
    </div>
</div>
