import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dailog',
  templateUrl: './confirm-dailog.component.html',
  styleUrls: ['./confirm-dailog.component.scss']
})
export class ConfirmDailogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmDailogComponent>) { }

  ngOnInit(): void {
  }
  delete(){
    this.dialogRef.close(true);
  }
  cancel(){
    this.dialogRef.close();
  }
}
