import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { ApiServiceService } from 'src/app/services/api.service';
import { ShareService } from 'src/app/services/share.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  lang = '1';
  backeventsubscription: any;

  constructor(private api: ApiServiceService, private share: ShareService) {
    this.lang = this.api.language_id
  }

  ngOnInit(): void {
    ////If Browser Back Button is clicked any loader will be turned to false
    // history.pushState(null, null, location.href);
    this.backeventsubscription = fromEvent(window, 'popstate').subscribe(_ => {
      this.share.getPropLoader.next(false);
      this.share.getPropformLoader.next(false);
    }); ////////
  }

  ngOnDestroy() {
    if (this.backeventsubscription) this.backeventsubscription.unsubscribe();
  }

}
