import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  page:any;@Inject(MAT_DIALOG_DATA) public data:any
  constructor(@Inject(DOCUMENT) private document: Document, @Inject(MAT_DIALOG_DATA) public parentdata:string = 'login', public dialogRef: MatDialogRef<UserComponent>, private dialog: MatDialog) {
    this.page = this.parentdata;
   }

  ngOnInit(): void {
   
  }
  signup(val){
    if(val == 'register'){
      this.page = val;
    }else if(val == 'forgotpassword'){
      this.dialogRef.close();
      const dialog = this.dialog.open(ResetPasswordComponent, {
        width: '400px',
        data: '',
        disableClose: true
      });
      setTimeout(()=>{
        this.document.body.classList.add('login_Modal');
      }, 200)
      
      dialog.afterClosed().subscribe((flag:any)=>{
        this.document.body.classList.remove('login_Modal');
      })
    }
    
  }
  signin(){
    this.page='login';
  }
  
}
