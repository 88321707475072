import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/components/alerts/alert.service';
import { CustomValidator } from 'src/app/custom-validator';
import { ApiServiceService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  OTPForm: UntypedFormGroup;
  newPasswordForm:UntypedFormGroup;
  errormessege: string = '';
  visible_section = 'email_input';
  submited: boolean = false;
  button_state: boolean = false;
  visible_OTP_section='';

  couterdown:any;
  counter:any;
  timemer;
  timerLimit = 60;
  loading: boolean = false;
  resetLoader: boolean=false;

  constructor(public dialog: MatDialog, private alertService: AlertService, public fb: UntypedFormBuilder, public api: ApiServiceService, private el: ElementRef) {

   }

   startCounter(){
    this.timemer = this.timerLimit;
    this.counter = setInterval(()=>{
        this.timemer--;
        const minutes = Math.floor(this.timemer / 60);
        const seconds = this.timemer - minutes * 60;
        this.couterdown = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
        if (this.timemer == 0) {
          clearInterval(this.counter);
          this.couterdown = '0:00'
          this.button_state = false;
        }
      }, 1000)
   }
  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: ['', [
        Validators.email,
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ]
    });

    this.OTPForm = this.fb.group({
      mail_otp: ['', Validators.compose([Validators.required, Validators.maxLength(6), Validators.maxLength(6)])]
    });


    this.newPasswordForm = this.fb.group({
      password: [null, Validators.compose([
        Validators.required,
        CustomValidator.patternValidator(/\d/, { hasNumber: true }),
        CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidator.patternValidator(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, { hasSpecialCharacters: true }),
        Validators.minLength(8)])
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      // check whether our password and confirm password match
      validator: CustomValidator.passwordMatchValidator
    })
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }


  closeReset() {
    this.dialog.closeAll();
  }

  get email() {
    return this.forgotForm.get('email')!;
  }


  onSubmit(formvalue: any) {
    this.button_state = true;
    this.OTPForm.reset();
    for (const key of Object.keys(this.forgotForm.controls)) {
      if (this.forgotForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
    if (this.forgotForm.invalid) return;
    let body = {
      "email": formvalue.email,
      "notification_type": "email_otp"
    }
    this.submited = true;
    this.api.postUmsData('/notification', body).subscribe({
      next: (res: any) => {
        if (res.status == 201) {
          this.startCounter();
          this.forgotForm.controls['email'].disable();
          this.visible_OTP_section = 'fill_opt'
          if(this.couterdown == '0:00'){
            this.alertService.success("OTP sent to your Email address");
          }else{
            this.alertService.success("OTP sent successfully, Please check your Email address");
          }
        } else {
          this.button_state = false;
          this.alertService.warn(res.message);
        }
        this.submited = false;
      },
      error: (err) => {
        this.alertService.warn(err.error);
        this.submited = false;
        this.button_state = false;
      }
    })
  }



  SubmitOTP(formvalue: any) {

    if (this.OTPForm.invalid) return;

    let body = {
      "email": this.forgotForm.get('email').value,
      "type": "email_otp",
      "token": formvalue.mail_otp
    }
    this.loading = true;
    this.api.postUmsData('/verify', body).subscribe({
      next: (res: any) => {
        if (res.status == 201) {
          this.visible_OTP_section = ''
          this.visible_section = 'new_password';
          this.alertService.success(res.message);
        } else {
          this.alertService.warn(res.message);
        }
        this.loading = false;
      },
      error: (err) => {
        this.alertService.warn(err.error);
        this.loading = false;
      }
    })
  }


  resendOTP(){
    // this.startCounter();
    this.onSubmit(this.forgotForm.value);
  }
  submitevent(){
    this.SubmitOTP(this.OTPForm.value);
  }
  resetPassword(fvalue){
    if(this.newPasswordForm.invalid) {
      return;
    }
    if(fvalue.password !==  fvalue.confirmPassword){
        this.alertService.warn('Password Mismatch');
        return;
    }
    let body={
      "email": this.forgotForm.get('email').value,
      "type": "email_otp",
      "token": this.OTPForm.get('mail_otp').value,
      "password": fvalue.password
    };
    this.resetLoader= true;
    this.api.patchUmsData('reset-password', body).subscribe({
      next: (res: any) => {
        this.newPasswordForm.reset();
        this.resetLoader= false;
        if (res && res.status == 201) {
          this.closeReset();
          this.alertService.success(res.message);
        }
        else {
          this.alertService.warn(res.message);
        }
      },
      error: (err) => {
        this.newPasswordForm.reset();
        this.resetLoader= false;
        this.alertService.warn(err.error.message);
      }
    })

  }
}
