import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';



import {
  makeStateKey,
  StateKey,
  TransferState
} from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(private http: HttpClient, private transferState: TransferState) {}

  private prefix: string = 'i18n';
  private suffix: string = '.json';
  

  public getTranslation(lang: string): Observable<any> {
    if (typeof localStorage == 'undefined') { // it is server
      var fs = require('fs');
      var join = require('path').join;
      
      return new Observable((observer) => {
        const assets_folder = join(
          process.cwd(),
          'dist',
          'ecem',
          'browser',
          'assets',
          this.prefix
        );
    
        const jsonData = JSON.parse(
         fs.readFileSync(`${assets_folder}/${lang}${this.suffix}`, 'utf8')
        );
    
        // Here we save the translations in the transfer-state
        const key: StateKey<number> = makeStateKey<number>(
          'transfer-translate-' + lang
        );
        this.transferState.set(key, jsonData);
    
        observer.next(jsonData);
        observer.complete();
      });

      
    
    }else{ // browser
      const key: StateKey<number> = makeStateKey<number>(
        'transfer-translate-' + lang
      );
      const data = this.transferState.get(key, null);
  
      // First we are looking for the translations in transfer-state, 
    // if none found, http load as fallback
      if (data) {
        return new Observable((observer) => {
          observer.next(data);
          observer.complete();
        });
      } else {
        return new TranslateHttpLoader(this.http).getTranslation(lang);
      }
    }
 

 

}
}


export function translateBrowserLoaderFactory(
  httpClient: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(httpClient, transferState);
}