import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from '../../../services/api.service';
import { BrowserService } from '../../../services/browser.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserComponent } from 'src/app/home/component/user/user.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonfunctionService } from 'src/app/services/commonFunctionService';
import { ShareService } from 'src/app/services/share.service';
import { AlertService } from '../alerts/alert.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CitySelectionDialogComponent } from '../city-selection-dialog/city-selection-dialog.component';
import { CityData } from 'src/app/home/models/cityData';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit , AfterViewInit{
  selected_lang: any = this.browser.getLocalValue(
    'LOCALIZE_DEFAULT_LANGUAGE'
  );
  public em_ang = environment.em_ang;
  public cities: any = [];
  public urlLang = '';
  broker_list: any=[];
  user_name: string;
  selected_broker_id: any;
  profile_image: any;
  selectedCity: any;
  selected_city_slug: any;
  // view_city_code='';

  page:string='ec';
  selectedCityControl = new FormControl();
  mobile: boolean;
  isHomePage:boolean;
  miApplogin=environment.miApp; 
   mobileApp: boolean=false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public api: ApiServiceService,
    private browser: BrowserService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private fnc: CommonfunctionService,
    private share:ShareService,
    private alertService: AlertService,
    private activatedRoute : ActivatedRoute
  ) {
    this.mobile = this.api.isMobile;
        
    this.activatedRoute.queryParamMap.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((paramsMap: any) => {
      if (paramsMap && paramsMap.params && paramsMap.params['mobile_app']) {
        this.mobileApp = paramsMap.params['mobile_app'] == 'true' ? true : false;
      } else {
        this.mobileApp = false;
      }
    })

    this.selected_city_slug = this.getSelectedCity();
    let lang = this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
    this.api.cities.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(city=>{
      this.cities = city; 
      if(this.cities) this.cities.sort((a: any, b: any) => (a.place_name < b.place_name ? -1 : 1));
      if(this.selected_city_slug == '' && !city && !city?.length) return;
      let slug: string = (this.router.url?.split('-'+lang)[0])?.split('/')[1];
      let matchSlug = city?.find(item => item.slug == slug)?.slug;
      if(matchSlug) this.selected_city_slug = matchSlug; 
    });
    let url: string = this.router.url;
    let lan = this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
    let slug = (this.router.url != '/')?(this.router.url.split('-'+lan)[0]).split('/')[1]:null;
    this.selectedCityControl.patchValue(slug);

    this.urlLang= `${this.selected_city_slug}-${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE')}`;
    if (url.includes(`/estater-meter-gis`)) {
      this.page = 'em';
    } else if (url.includes('/dashboard')) {
      this.page = 'dashboard';
    } else {
      this.page = 'ec';
    }
    // 
    router.events
    .pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      ( event: NavigationStart ) => {
        let url: string = this.router.url;
        let lan = this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
        let slug = (this.router.url != '/')?(this.router.url.split('-'+lan)[0]).split('/')[1]:null;
        this.selectedCityControl.patchValue(slug);
          if(`/${this.urlLang}` == url || `/` == url){
            this.isHomePage = true;
          }else{
            this.isHomePage = false;
          }
          if (url.includes(`/estater-meter-gis`)) {
            this.page = 'em';
          } else if (url.includes('/dashboard')) {
            this.page = 'dashboard';
          } else {
            this.page = 'ec';
          }
      });
    // 
  }
  
  onCountryChange(event:any, redirect:boolean=true){
      let selectedCity = this.share.countryFind(event.value, this.cities);
      if( selectedCity){
        if(!redirect) this.selectedCityControl.patchValue(selectedCity.slug);
        if(redirect){
          this.share.changeHomepageCIty.next(event);
          this.browser.setLocalValue('selected_city', JSON.stringify(selectedCity))
          this.api.selected_city.next(selectedCity);
          this.redirectSelectedCity(selectedCity.slug);
        }
      } 
  }
  redirectSelectedCity(city_id:string){
    let lang = this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
    let url: string = this.router.url.split('-'+lang)[1];
    if(city_id) this.router.navigate([`/${city_id}-${lang}${url}`]);
  }
  async redirectToSelectedCity(city, child:boolean=false){
    let path=(!child)?this.api.getPath(city.slug, ''):this.api.getPath(city.slug, '');
      let url = path+'estater-meter-gis';
      this.router.navigateByUrl(url);
  }
  tabClick(value){
    this.share.home_tab.next(value)
  }
  getSelectedCity(){
      let selected_city = this.browser.getLocalValue('selected_city');
      if(selected_city){
        selected_city = JSON.parse(selected_city);
        this.selected_city_slug = selected_city?.slug?.toLowerCase();
        return selected_city?.slug?.toLowerCase();
      }
      return '';
  }
  ngOnInit(): void {
    this.share.changeHeaderCIty.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(event =>{
      if(event)this.onCountryChange(event, false);
    })
    
    this.api.selected_city.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(city=>{
      if(city) {
        this.selectedCity = city;
        this.selected_city_slug = this.selectedCity?.slug?.toLowerCase();
        this.urlLang= `${this.selected_city_slug}-${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE')}`;
        this.api.current_city_slug.next(this.urlLang)
      } else {
        setTimeout(()=>{
          this.urlLang= `${this.selected_city_slug}-${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE')}`;
          this.api.current_city_slug.next(this.urlLang)
        }, 1200);
      }
    })

    this.api.lang.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((lang) => {
      this.selected_lang = lang;
      this.urlLang= `${this.selected_city_slug}-${lang}`;
      this.api.current_city_slug.next(this.urlLang)
    });
    this.share.openCityModal.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((flag)=>{
      if(!this.getSelectedCity() && flag && !this.mobile){
        this.selectCityModal('', false, true, true)
      } 
    })
    this.getBrokerdetails();

    
    this.api.user_info.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res:any) => {
      if (res && res.user_id) {
        this.user_name =  `${res?.first_name} ${res?.last_name}`;
        let brokers:any = (res.hasOwnProperty("broker_list") && res.broker_list.length > 0)  ? res.broker_list : [];
        this.createBrokerList(brokers);
        if(res?.brokerData && res.brokerData != ''){
          this.selected_broker_id = res.brokerData.broker_id;
        } else {
          this.selected_broker_id = null;
        }
      }else{
        this.user_name = '';
      }
    });
   this.api.profile_image.pipe(
    takeUntil(this.unsubscribe$)
  ).subscribe(img=>{
      if(img){
        this.profile_image = img;        
      } else{
        this.profile_image =null;
      } 
    })
    
  }
  ngAfterViewInit(){}
  ngOnDestroy(): void {
    this.cities;this.urlLang;this.broker_list;this.selected_city_slug;this.selectedCity;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getBrokerdetails(){
    if(this.api.user_id){
      this.api.postBrokerData('broker-login', {"ums_id": this.api.user_id}).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((broker:any)=>{
          if(broker.data && broker.data.length > 0){
            let user_info = this.browser.getLocalValue('user_info') ? JSON.parse(this.browser.getLocalValue('user_info')):'';
            user_info['broker_list'] = broker.data;
            this.api.user_info.next(user_info);
            this.browser.setLocalValue('user_info', JSON.stringify(user_info));
          }
        })
    }
  }

  shownav(id) {
    let nav = document.getElementById(id);
    let hide = document.getElementsByClassName('show');
    for (let i = 0; i < hide.length; i++) {
      hide[i].classList.remove('show');
    }
    nav.classList.add('show');
  }

  showHideNav() {
    // this.show=!this.show;
    document.getElementById('navigation').classList.remove('nav_active');
    document.getElementById('navigation').classList.add('none');
  }

  selectCityModal(val:any, isBroker:boolean = false, idDefault:boolean=false, hideClose:boolean=false){
    if(hideClose){
      this.selectedCity ={};
      this.selectedCity['hideClose'] = hideClose;
      this.selectedCity['hideClosebutton'] = hideClose;
    }
    this.dialog.closeAll();
    const citySelectDialogRef = this.dialog.open(CitySelectionDialogComponent, {
      disableClose: true,
      maxWidth: this.mobile ? '95vw' : '80vw',
      width: this.mobile ? '320px':'',
      data: this.selectedCity,
      panelClass:'my-dialog-box_backdrop'
    });
    citySelectDialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      if (res) {
        let url =  `initial-load?city_slug=${res.slug}`;
        this.api.getData(url).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe((city)=>{
            if(city && city.data && city.data.city){
              this.browser.setLocalValue('selected_city', JSON.stringify(new CityData(city.data.city)));
              this.api.selected_city.next(new CityData(city.data.city));
            }else{
              this.alertService.warn(city.message)
            }
        });
      }
    });
  }
/**
 * select city from dropdown 
 * @param val url
 */
  goToDashboard(val:any, isNormalUser:boolean=false){
    let user = JSON.parse(this.browser.getLocalValue('user_info'));
      if(user?.brokerData && isNormalUser){
        let confirm_dialog = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          maxWidth: '100vh',
          data: this.translate.instant('User.confirm_user'),
          disableClose: true,
        })
        confirm_dialog.afterClosed().pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe((res) => {
          if(res){
            user["brokerData"] = '';
            this.browser.setLocalValue('user_info', JSON.stringify(user));
            this.api.user_info.next(user);
            let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}${val}`;
              this.router.navigate([path]);
          }
        })
        
      } else {
        let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}${val}`;
        this.router.navigate([path]);
    }
    
  }
  openDialog() {
    if (this.api.user_id) {
     
    } else {
      const userDailogRef = this.dialog.open(UserComponent, {
        width: '400px',
        maxWidth: '100vh',
        data: 'login',
        disableClose: true,
      });
      this.document.body.classList.add('login_Modal');
      userDailogRef.afterClosed().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((flag)=>{
        this.document.body.classList.remove('login_Modal');
        if(flag?.event){
          if(!this.getSelectedCity()){
            this.selectCityModal('', false, true, true)
          } 
        }
      })
    }
  }

  
  createBrokerList(data:any){
    this.broker_list=[];
    if(data && data.length){
      let isExist = (compId) => {
        for (var value of this.broker_list) {
            if (value.company_id == compId) {
                return true;
            }
        }
        return false;
      };
      data.forEach((value)=> {
        if (isExist(value.company_id)) {
            this.broker_list.forEach((obj)=> {
                if (obj.company_id == value.company_id) {
                    obj.brokers.push(value);
                }
            });
        }
        else {
          let company_name = value.company_name[this.api.language_id]?value.company_name[this.api.language_id]:value.company_name[1];
            let obj = { "company_id": value.company_id, "company_name": company_name, "brokers": [] };
            obj.brokers.push(value);
            this.broker_list.push(obj);
        }
      });
    }
  }

  selectBroker(broker){
    let user = JSON.parse(this.browser.getLocalValue('user_info'));
    if(user?.brokerData && (user?.brokerData.broker_id ==   broker.broker_id)){
        let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}/dashboard/settings/profile`;
        this.router.navigate([path]);
    }else{
      let dialogRef  = this.dialog.open(UserComponent, {
        width: '400px',
        maxWidth: '100vh',
        data: 'broker',
        disableClose: true,
      });
      dialogRef.afterClosed().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res) => {
        if(res.event){
          user["brokerData"] = broker;
          this.browser.setLocalValue('user_info', JSON.stringify(user));
          this.api.user_info.next(user);
          this.alertService.success(broker?.user_type_name+ ' account selected');
          let path:any = `${this.browser.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}/dashboard/settings/profile`;
          this.router.navigate([path]);
        }        
      });
    }
  }
  
   /**
   * This is function to use get first charctor of first anme and last name of user_name
   * Like Vishnu Kant
   * @param e string user name (Vishnu kant)
   * @returns string - VK
   */
   getInitials(e) {
    return this.fnc.getInitials(e);
  }


  /**
   * this is a function to select city from dropdown
   * @param city 
   */
  selectCity(city) {
    if(city && city.slug != null){
      this.share.selected_section.next('');
      let path = this.api.getPath(city.slug, '');
      this.router.navigate([path]);
    }
  }
}
