
export class DistrictData {
    
    lat: number;
    lon: number;
    neigh_name: any;
    neigh_uid: string='';
    occupancy: number;
    shape_area: number;
    neigh_id: number;
    slug:string

   constructor(Values:any={}){
       Object.assign(this, Values);
   }
}