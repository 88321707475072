<div class="main-div">
   <div class="top-section">
        <img class="close" src="assets/icons/Cross_purple.svg" alt="cancel" (click)="cancel()">
        <img src="assets/icons/unsave_property.svg" alt="unsave_property">
        <p>{{'Home.unsave_property'| translate}}
            {{'Home.longer_receive_updates'| translate}}</p>
    </div>
    <div class="bottom-section">
        <button class="btn save_button" (click)="delete()">{{'Home.delete'| translate}}</button>
    </div>
</div>