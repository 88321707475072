import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EMFooterComponent } from '../components/footer/footer.component';
import { SelectLanguageComponent } from '../components/select-language/select-language.component';
import { AlertService } from '../components/alerts/alert.service';
import { AlertComponent } from '../components/alerts/alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { HeaderComponent } from '../components/header/header.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MobileHeaderComponent } from '../components/mobile-header/mobile-header.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MobileMenuComponent } from '../components/mobile-menu/mobile-menu.component';
import { ClickOutsideDirective } from '../components/mobile-header/click-outside.directive';
import { CityDialogButtonComponent } from '../components/city-dialog-button/city-dialog-button.component';
import { SubmitQueryFormComponent } from '../components/submit-query-form/submit-query-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [EMFooterComponent, SelectLanguageComponent, AlertComponent, HeaderComponent, MobileHeaderComponent,
        MobileMenuComponent, ClickOutsideDirective, CityDialogButtonComponent, SubmitQueryFormComponent],
    imports: [
        CommonModule,
        CdkAccordionModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        TranslateModule,
        RouterModule,
        LocalizeRouterModule,
        NgxIntlTelInputModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
    providers: [AlertService],
    exports: [EMFooterComponent, SelectLanguageComponent, AlertComponent, TranslateModule, CommonModule, HeaderComponent, MobileHeaderComponent,
        MobileMenuComponent, CityDialogButtonComponent, SubmitQueryFormComponent]
})
export class SharedModule{}
