import { Component, OnInit, PLATFORM_ID, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserComponent } from 'src/app/home/component/user/user.component';
import { ApiServiceService } from 'src/app/services/api.service';
import { BrowserService } from 'src/app/services/browser.service';
import { CitySelectionDialogComponent } from 'src/app/core/components/city-selection-dialog/city-selection-dialog.component';
import { ShareService } from 'src/app/services/share.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, distinctUntilChanged, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { CityData } from 'src/app/home/models/cityData';

// declare var $:any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  @Input() page;
  @Input() slug;
  @Input() broker_list:any[]=[];

  @Output() select_broker = new EventEmitter;

  
  mobile:boolean;
  user_id:any;
  user_info:any;
  profile_image:string;
  fullname:string;
  current_year;
  selectedCity: any;
  citySubscriptiob:Subscription;

  menu_list=[
    {
      icon:'mi.svg',
      label:"header_menu.product.title",
      sub_menu_list:[
        { page_link:"/estater-market-overview", page_name: "header_menu.product.Market_Intelligence", sub_title: 'header_menu.product.product_text3', settab:'' },
        { page_link:"/api", page_name: "header_menu.product.API", sub_title: 'header_menu.product.product_text4', settab:'' },
      ]
    },
    {
      icon:'est.svg',
      label:"header_menu.solutions.title",
      sub_menu_list:[
        { page_link:"/estater-meter-features?section=homeowner", page_name: "header_menu.solutions.Buyer", sub_title: 'header_menu.solutions.solutions_text1', settab:'' },
        { page_link:"/estater-meter-features?section=agents", page_name: "header_menu.solutions.Broker", sub_title: 'header_menu.solutions.solutions_text2', settab:'' },
        { page_link:"/estater-meter-features?section=bank", page_name: "header_menu.solutions.Bank", sub_title: 'header_menu.solutions.solutions_text3', settab:'' },
        { page_link:"/estater-meter-features?section=company", page_name: "header_menu.solutions.Corporate", sub_title: 'header_menu.solutions.solutions_text4', settab:'' }
      ]
    },
    {
      icon:'est.svg',
      label:"header_menu.resources.title",
      sub_menu_list:[
        { page_link: "https://estater.com/blog/", page_name: "landing_page.news_events", sub_title: '', target: true , settab:''},
        { page_link: "/privacy-policy", page_name: "landing_page.footer_privacy", sub_title: '', target: false, settab:'' },
        { page_link: "/contact", page_name: "landing_page.footer_contact", sub_title: '', target: false, settab:'' }
      ]
    },
    {
      icon:'est.svg',
      label:"header_menu.Estater.title",
      sub_menu_list: [
        { page_link:"/estater-overview", page_name: "about_estater.aboutestater_overview", sub_title: '', settab:'' },
        { page_link:"/estater-what-we-do", page_name: "about_estater.aboutestater_whatwedo", sub_title: '', settab:'' },
        { page_link:"/estater-case-studies", page_name: "about_estater.aboutestater_casestudies", sub_title: '', settab:'' },
        { page_link:"/estater-geo-estaters", page_name: "about_estater.aboutestater_geoestater", sub_title: '', settab:'' },
        { page_link:"/estater-who-we-are", page_name: "about_estater.aboutestater_whoweare", sub_title: '', settab:'' }
      ]
    },
    {
      icon:'est.svg',
      label:"header_menu.Estater_Meter.title",
      sub_menu_list: [
        { page_link:"/estater-meter-overview", page_name: "estater_meter.subnav_l1", sub_title: '', settab:'' },
        { page_link:"/estater-meter-features", page_name: "estater_meter.subnav_l6", sub_title: '', settab:'' },
        { page_link:"/estater-meter-avm", page_name: "estater_meter.subnav_l2", sub_title: '', settab:'' },
        { page_link:"/estater-meter-data-science", page_name: "estater_meter.subnav_l3", sub_title: '', settab:'' },
        { page_link:"/estater-meter-machine-learning", page_name: "estater_meter.subnav_l4", sub_title: '', settab:'' },
        { page_link:"/estater-meter-methodology", page_name: "estater_meter.subnav_l5", sub_title: '', settab:'' }
      ]
    },
    {
      icon:'est.svg',
      label:"header_menu.Market_Intelligence.title",
      sub_menu_list: [
        { page_link:"/estater-market-overview", page_name: "mi_intelligence.mi_overview", sub_title: '', settab:'' },
        { page_link:"/estater-market-reports", page_name: "mi_intelligence.mi_reports", sub_title: '', settab:'' },
        { page_link:"/estater-market-free-reports", page_name: "mi_intelligence.mi_free_reports", sub_title: '', settab:'' }
      ]
    }
  ]
  userSubscriber: Subscription; 
  modalSubscriber: Subscription; 
  profileSubscriber: Subscription;
  selected_broker_id: any;
  citySelectDialogRef: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(@Inject(PLATFORM_ID) private platformId: any, public api:ApiServiceService, private router:Router, 
  public dialog: MatDialog, private browserService: BrowserService,
  private share: ShareService,private localizeRouterService: LocalizeRouterService,
  private notify:NotificationService,
  private translate: TranslateService) {
    this.mobile = this.api.isMobile;
    this.current_year = new Date().getFullYear();
    this.userSubscriber = this.api.user_info.subscribe((user_info:any)=>{
      if(user_info){
        this.user_id = user_info.user_id;
        this.user_info = user_info;
        let fname = (this.user_info.first_name)?this.user_info.first_name:''; 
        let lname = (this.user_info.last_name)?this.user_info.last_name:'';
        this.fullname = fname +' '+lname;

        if(user_info?.brokerData && user_info.brokerData != ''){
          this.selected_broker_id = user_info.brokerData.broker_id;
        } else {
          this.selected_broker_id = null;
        }

      }else{
        this.user_id = null;
        this.user_info = null;
        this.fullname = null;
      }
    })
    this.profileSubscriber = this.api.profile_image.subscribe(img=>{
      if(img){
        this.profile_image = img;
      }else{
        this.profile_image=''
      }    
    })
   }

   selectBroker(broker){
    this.select_broker.emit(broker);
   }
  ngOnInit(): void {
    let temcity=[];  
    try {
      // this.api.getCitis().then((data:any)=>{
        this.api.cities.pipe(
          takeUntil(this.unsubscribe$),
          distinctUntilChanged(),
        ).subscribe((data:any)=>{
        if(data && data.length){  
          temcity=[]  
          let lang = this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');    
          data.map((ele:any)=>{
            let path:any = `${ele.slug}-${lang}/estater-meter-gis`;
            // let child_data = ele.child_data;
            // if(child_data){
            //   child_data.forEach((el, index) =>{
            //     child_data[index]['slug'] = `${el.slug}-${lang}/estater-meter-gis`
            //   });
            // } 
            let name = ele.child_data ?  ele.place_name : this.translate.instant("city.explore") + ' ' + ele.place_name;
            temcity.push({
              page_link: path,
              isCity: true,
              page_name:  name,
              child_data: ele.child_data
            })
          })
        //   temcity.push({ page_link:"/estater-meter-overview", page_name: "landing_page.about_estater_meter", sub_title: ''})
        // } else{
        //   temcity.push({ page_link:"/estater-meter-overview", page_name: "landing_page.about_estater_meter", sub_title: ''})
        }
      // }).catch(err=>{
      //   temcity.push({ page_link:"/estater-meter-overview", page_name: "landing_page.about_estater_meter", sub_title: ''})
      });
     } catch (error) {
      temcity.push({ page_link:"/estater-meter-overview", page_name: "landing_page.about_estater_meter", sub_title: ''})
    }
    temcity.push({ page_link: environment.miApp, page_name: "header_menu.customers.Market_Intelligence_Login", sub_title: '', target: true })
    temcity.push({ page_link: environment.miApp+'request-demo', page_name: "header_menu.customers.request_demo", sub_title: '', target: true })
    let obj = {
      icon:'em.svg',
      label:"header_menu.customers.title",
      sub_menu_list: temcity
    }
    this.menu_list.splice(2,0, obj)

    this.api.selected_city.subscribe(city=>{
      this.selectedCity = city;
    })
    if(this.modalSubscriber) this.modalSubscriber.unsubscribe();
    this.modalSubscriber = this.share.openCityModal.subscribe((flag)=>{
      if(!this.getSelectedCity() && flag){
        this.selectCityModal('', false, true, true)
      } 
    })
  }
  ngOnDestroy(): void {
    if(this.citySubscriptiob) this.citySubscriptiob.unsubscribe();
    if(this.userSubscriber) this.userSubscriber.unsubscribe();
    if(this.profileSubscriber) this.profileSubscriber.unsubscribe();
  }

  cityselect(){
    const dialogRef = this.dialog.open(CitySelectionDialogComponent, {
      disableClose: true,
      // width: '90vw',
      maxWidth:'95vw',
      data: this.selectedCity,
      panelClass:'my-dialog-box_backdrop'
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let value = res;
        this.browserService.setLocalValue(
          'selected_city',
          JSON.stringify(value)
        );
        this.api.selected_city.next(value);
        this.share.select_city(value);
        let path:any = this.api.getPath(value.slug, '');
        // let path:any = `${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar' ? 'ar/': ''}em/${value.slug.toLowerCase()}`
        this.router.navigateByUrl(path + 'estater-meter-gis');
      }
    });
  }
  /**
   * open user component dialog
   * @param val string 
   */
  openDialog(val:string){
    const userDailogRef = this.dialog.open(UserComponent, {
      width: '100vw',
      maxWidth:'100vw',
      height: '100vh',
      data: val,
      disableClose: true
    });
    userDailogRef.afterClosed().subscribe((flag)=>{
      if(flag?.event && !this.getSelectedCity() && this.mobile){
          this.selectCityModal('', false, true, true)
      }
    })
  }
/**
 * city modal
 * @param val 
 * @param isBroker 
 * @param idDefault 
 * @param hideClose 
 */
  selectCityModal(val:any, isBroker:boolean = false, idDefault:boolean=false, hideClose:boolean=false){
    if(hideClose){
      this.selectedCity ={};
      this.selectedCity['hideClose'] = hideClose;
    }
    
    this.citySelectDialogRef = this.dialog.open(CitySelectionDialogComponent, {
      disableClose: true,
      // width: '80vw',
      maxWidth:'80vw',
      data: this.selectedCity
    });
    this.citySelectDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let url =  `initial-load?city_slug=${res.slug}`;
        this.api.getData(url).subscribe((city)=>{
            if(city && city.data && city.data.city){
              this.browserService.setLocalValue('selected_city', JSON.stringify(new CityData(city.data.city)));
              this.api.selected_city.next(new CityData(city.data.city));
              if(idDefault){
                //do action if user opted a city
              } else if(isBroker){
                let path:any = `${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}/dashboard/settings/profile`;
                this.router.navigate([path]);
              } else {
                let path:any = `${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'?'ar':''}${val}`;
                this.router.navigate([path]);
              }
            }
        });
      }
    });
  }
  /**
   * get city from local stroge
   * @returns selected city slug
   */
  getSelectedCity(){
    let selected_city = this.browserService.getLocalValue('selected_city');
    if(selected_city){
      selected_city = JSON.parse(selected_city);
      return selected_city?.slug?.toLowerCase();
    }
    return '';
}
  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   $(document).ready(function () {
    //     $('.lang_dropdown').on('click', function(){
    //       if($('#lang').css('display') == 'none'){
    //         $('.lang_dropdown').children().next().css({
    //           'transform':'rotate(180deg)',
    //           'transition':'all linear 0.2;'
    //         });
    //       }
    //       else{
    //         $('.lang_dropdown').children().next().css({
    //           'transform':'rotate(0deg)',
    //           'transition':'all linear 0.2;'
    //         });
    //       }
    //     });
    //   });
    // }
  }
  // startTour(){
  //   this.browserService.setCookie('introStage', 0, 0);
  //   this.browserService.introStage.next(0);
  //   this.browserService.reload();
  // }

    /**
   * redirect to em home page 
   */
    close(){
      // `${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar'? 'ar':''}/em/${this.selectedCity.slug}`
      let path = (this.api.getPath(this.selectedCity.slug, '')).split('/')[0];
      this.router.navigateByUrl(path)
    }

    rout(url:string, target:boolean=false, set_tab=null, isCity:boolean=false, isMobile:boolean=false){
      this.resetMenu();
      let cityUrl = `${url}-${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE')}/estater-meter-gis`;
      let path:any = (!isCity)?`${this.slug}${url}`: isMobile?`${cityUrl}`:`${url}`;
      if(set_tab){
        this.share.home_tab.next(set_tab)
      }
      if(target){
        this.browserService.openNewWindow(url)
      }else{       
        this.router.navigateByUrl(path);
      }

    }
    resetMenu(){
      let moiblemenuid = this.browserService.getElements('ById','mobile_menu')
      let mobileoverlay = this.browserService.getElements('ById','mobile_menu_overlay')
      moiblemenuid.classList.add('d-none');
      mobileoverlay.classList.add('d-none');
    }
    logout(){
      this.resetMenu();
      this.api.logout();
      this.notify.show(this.translate.instant('User.user_logout'))
      this.close();
    }
}
