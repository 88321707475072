import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AlertService } from 'src/app/core/components/alerts/alert.service';
import { BrowserService } from 'src/app/services/browser.service';
import { ShareService } from 'src/app/services/share.service';
import { CustomValidator } from 'src/app/custom-validator';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  isMobile: boolean = false;
  registerForm: UntypedFormGroup;
  isSubmited = false;
  errormessege: string = '';
  successmessege: string = '';
  loading: boolean = false;
  isTrue: boolean = false;
  @Output() login = new EventEmitter();
  constructor(private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private api: ApiServiceService,
    private alertService: AlertService,
    private el: ElementRef,
    private browserService: BrowserService,) {
    this.isMobile = this.api.isMobile;
  }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(45), Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.maxLength(45), Validators.minLength(2)]],
      // email: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, Validators.compose([
        Validators.required,
        CustomValidator.patternValidator(/\d/, { hasNumber: true }),
        CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidator.patternValidator(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, { hasSpecialCharacters: true }),
        Validators.minLength(8)])
      ],
      confirmPassword: ['', Validators.required],
    },
      {
        // check whether our password and confirm password match
        validator: CustomValidator.passwordMatchValidator
      });
  }
  get email() {
    return this.registerForm.get('email')!;
  }
  onSubmit(formvalue: any) {
    for (const key of Object.keys(this.registerForm.controls)) {
      if (this.registerForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
    if (this.registerForm.invalid) return;

    this.successmessege = '';
    this.errormessege = '';

    let matched: boolean = this.isPassword(formvalue.password);
    if (!matched) {
      this.errormessege = 'Password must: Min 8 and Max 20 Letters, Upper & lower case letters, a symbol and a special character.';
      return;
    } else {
      this.errormessege = '';
    }
    if (formvalue.password !== formvalue.confirmPassword) {
      this.errormessege = 'Confirm password not match'
      return;
    }
    else {
      this.errormessege = '';
    }
    let attr = {};
    attr[this.api.language_id] = { 'first_name': formvalue.firstName, 'last_name': formvalue.lastName }
    let body = {
      "user_email": formvalue.email,
      "password": formvalue.password,
      "attributes": attr,
    }
    this.loading = true;
    this.api.postUmsData('register', body).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.registerForm.reset();
        this.dialog.closeAll();
        if (res.status == 201) {
          if(res?.already_exit){
            this.alertService.warn(res.message);
          } else {
            this.alertService.success(res.message);
          }
        } else {
          this.alertService.warn(res.message);
        }
      },
      error: (err) => {
        this.loading = false;
        this.alertService.warn(err.error.message);
      }
    })
  }
  showVar: boolean;
  toggleEyeImage() {
    this.showVar = !this.showVar;
  }
  //password validation
  isPassword(password: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return re.test(password);
  }
  onReset() {
    this.registerForm.reset();
  }
  closePopup() {
    this.dialog.closeAll();
  }
  login_() {
    this.login.emit();
  }
}

