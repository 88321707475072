import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, distinctUntilChanged, take } from 'rxjs';
import { AlertService } from '../core/components/alerts/alert.service';
import { BlockData } from '../home/models/block';
import { ValutionData } from '../home/models/valuation_data';
import { ApiServiceService } from './api.service';
import { BrowserService } from './browser.service';
import { CommonfunctionService } from './commonFunctionService';
import { LoadDataService } from './load-data.service';
import { UserAction } from '../home/user_action/user-action';

@Injectable()
export class ShareService {
  public neigh_metaDat: any;
  public selected_neigh_uid: string;
  public bockDetail: any = {};
  public neighDetail: any = {};
  public selectedCityTrendsData: any = {};
  public selectedNevehoodrTrendsData: any = {};
  public selectedBlockTrendsData: any = {};
  public selectedBlockTrendsDataProperty: any = {};
  public landing_obj: any;
  public show_categories: boolean = true;
  public pro: any = [];
  public blockList = new BehaviorSubject<any>('');
  public neighbourhoodsDescrition = new BehaviorSubject<any>('');
  public backblockList = new BehaviorSubject<any>('');
  public propertyList = new BehaviorSubject<any>('');
  public backpropertyList = new BehaviorSubject<any>('');
  public nearbyCategoryList = new BehaviorSubject<any>('');
  public allNearbyCategoryType = new BehaviorSubject<any>('');
  public updatePropertyStatus = new BehaviorSubject<any>('');
  public savedSinglePropertyData = new BehaviorSubject<any>('');
  public trackedSinglePropertyData = new BehaviorSubject<any>('');
  public propertySearchTerm = new BehaviorSubject<any>('');
  public clearPropSearchterm = new BehaviorSubject<any>('');
  public propertycardLoader = new BehaviorSubject<any>(false);

  public changeHeaderCIty = new BehaviorSubject<any>('');
  public changeHomepageCIty = new BehaviorSubject<any>('');
  public selected_neigh = new BehaviorSubject<any>('');
  public browserBackEventData = new BehaviorSubject<any>('');
  public selected_block = new BehaviorSubject<any>(new BlockData());

  public selected_back_block = new BehaviorSubject<any>(new BlockData());
  public selected_property = new BehaviorSubject<any>('');
  public selected_section = new BehaviorSubject<any>('');
  public selectedNearby = new BehaviorSubject<any>('');
  public show_on_map = new BehaviorSubject<any>('');
  public savedPropertyFloor = new BehaviorSubject<any>('');
  public valuation_data = new BehaviorSubject<any>(new ValutionData()); // build property form data
  public land_valuation = new BehaviorSubject<any>(''); // calculate build property data
  public loader = new BehaviorSubject<any>(false);
  public isValuationModalOpen = new BehaviorSubject<any>(false);
  public landHistory = new BehaviorSubject<any>('');
  public buildupHistory = new BehaviorSubject<any>('');
  public getPropLoader = new BehaviorSubject<any>(false);
  public resetTabDetails = new BehaviorSubject<any>(false);
  public trackingData = new BehaviorSubject<any>(false);
  public openCityModal = new BehaviorSubject<any>(false);
  public home_tab = new BehaviorSubject<string>('valuate');
  public getmapScale = new BehaviorSubject<any>(false);
  public total_property_count = new BehaviorSubject<number>(0);
  public total_property_count_block = new BehaviorSubject<number>(0);
  public getPropformLoader = new BehaviorSubject<any>(false);

  // public city_code: string;
  public is_saved = new BehaviorSubject<any>(false);
  public preference: any;
  public neighSubscriber:Subscription;
  public preference_data: any;
  public neigh_data: any;
  savedProperties: any = [];
  floorMatch: any = [];
  public preference_id:any=null;
  constructor(
    public browserService: BrowserService,
    private api: ApiServiceService,
    private activatedRoute: ActivatedRoute,
    private fnc: CommonfunctionService,
    private alert: AlertService,
    private loadDataService: LoadDataService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

    this.broker_info.pipe(take(1)).subscribe((info: any) => {
      if (info) {
        this.api.broker_id = info.broker_id;
      }
    });
    this.preference_info.subscribe((info: any) => {
      if (info) {
        this.preference_data = info;
      }
    });
    // if(this.neighSubscriber)this.neighSubscriber.unsubscribe();
      this.neighSubscriber = this.selected_neigh.subscribe((neigh) => {
        if (neigh) {
          this.neigh_data = neigh;
        }
      });
  }
  public broker_info = new BehaviorSubject<any>(
    this.browserService.getLocalValue('broker_info')
      ? JSON.parse(this.browserService.getLocalValue('broker_info'))
      : null
  );
  public broker_list = new BehaviorSubject<any>(
    this.browserService.getLocalValue('broker_list')
      ? JSON.parse(this.browserService.getLocalValue('broker_list'))
      : null
  );
  public preference_info = new BehaviorSubject<any>(
    this.browserService.getLocalValue('preference_info')
      ? JSON.parse(this.browserService.getLocalValue('preference_info'))
      : null
  );
  public neighList = new BehaviorSubject<any>(
    this.browserService.getLocalValue('neigh_list')
      ? JSON.parse(this.browserService.getLocalValue('neigh_list'))
      : ''
  );
/**
 * countryFind function return selected city data
 * @param slug 
 * @returns 
 */
  countryFind(slug:string, cities:any){
    let city;
    cities.map((item:any)=>{
      if(item?.child_data){
        let child_city = item.child_data.find((city:any) => city.slug == slug);
        if(child_city) city = child_city;
      } else if (item.slug == slug){
        city = item;
      }
     })
     return city;
  }
  /**
   * get, set and update preference_uid
   * @param preference form data
  */
  setPreference(preference: any, get_history: boolean = false, isUpdate: boolean = false, preference_uid=null, pref_uid=null) {
    this.preference_id = preference_uid
    let preference_id = preference_uid?preference_uid:this.fnc.getParameterByName('preference');
    let user_id = this.api.user_id ? this.api.user_id : null;
    let session_id = this.api.session_id;
    let url = `preference?property_id=${preference.pid}`;
    if (!preference_id) {
      if (user_id) { url += `&user_id=${user_id}` } else { url += `&session_id=${session_id}` }
    } else {
      url += `&preference_uid=${preference_id}`
    }
    url += `&get_history=${get_history}`

    if (isUpdate && preference_id && ((this.preference_data?.user_id != null && this.preference_data?.user_id == user_id) || (this.preference_data.session_id == session_id))) {
      let body = {
        preference_uid: pref_uid?pref_uid:preference_id,
        data: preference
      }
      this.api.patchData('preference', body).pipe(take(1)).subscribe({
        next: (res: any) => {
          if (res && (res.status == 201)) {
            let data = res.data[0];
            preference['preference_uid'] = data.preference_uid;
            preference['user_id'] = user_id;
            preference['session_id'] = session_id;
            if (preference_id != data.preference_uid) {
              this.fnc.changeUrl('preference', data.preference_uid);
            }
            this.browserService.setLocalValue('preference_info', JSON.stringify(preference));
            this.preference_info.next(preference);
          }
        },
        error: (err) => {
          this.alert.warn(err);
        },
      })
      return;
    }
    if (!isUpdate) {
      this.api.getData(url).pipe(take(1)).subscribe({
        next: (ele: any) => {
          if (ele && ele.data?.length) {
            let data = ele.data[0]?.data;
            setTimeout(()=>{
              this.fnc.changeUrl('preference', ele.data[0].preference_uid);
            }, 500);
            data['preference_uid'] = ele.data[0].preference_uid;
            data['user_id'] = user_id;
            data['session_id'] = ele.data[0].session_id;
            this.browserService.setLocalValue('preference_info', JSON.stringify(data));
            this.preference_info.next(data);
          }
        },
        error: (err) => {
          this.alert.warn(err);
        }
      });
      return;
    }
    if (isUpdate && ((this.preference_data?.user_id != user_id) || (this.preference_data?.session_id != session_id))) {
      this.postPreferences(preference);
      return;
    }
    if (isUpdate && !preference_id) {
      this.postPreferences(preference);
    }
    //
  }
  /**
   * Post request to add preference
   * @param preference
   */
  postPreferences(preference) {
    let user_id = this.api.user_id ? this.api.user_id : null;
    let session_id = this.api.session_id;
    let body = {
      property_id: preference.pid,
      data: preference
    }
    if (user_id) {
      body['user_id'] = user_id;
    } else {
      body['session_id'] = session_id;
    }
    this.api.postData('preference', body).pipe(take(1)).subscribe({
      next: (res: any) => {
        if (res && (res.status == 201)) {
          let data = res.data[0];
          this.fnc.changeUrl('preference', data.preference_uid);
          preference['preference_uid'] = data.preference_uid;
          preference['user_id'] = user_id;
          preference['session_id'] = session_id;
          this.browserService.setLocalValue('preference_info', JSON.stringify(preference));
          this.preference_info.next(preference)
        }
      },
      error: (err) => {
        this.alert.warn(err);
      }
    })
  }

  // get neighbourhoods
  getneighbourhoods() {
    let list = [];
    this.api
      .getData('neighbourhoods/details?get_geom=false&get_centroid=true')
      .pipe(take(1)).subscribe({
        next: (ele: any) => {
        if (ele && ele.data?.length) {
          ele.data.map((neighData: any) => {
            let centroid: any = this.fnc.getlanlon(neighData.centroid);
            let popupdata = {
              is_show_marker: neighData.is_show_marker,
              lat: centroid?.lat ? centroid?.lat : '',
              lon: centroid?.lon ? centroid?.lon : '',
              media: neighData.entity_media,
              neigh_id: neighData.neigh_id,
              neigh_name:
                neighData.neigh_name &&
                  neighData.neigh_name[this.api.language_id]
                  ? neighData.neigh_name[this.api.language_id]
                  : neighData.neigh_name['1'],
              neigh_uid: neighData.neigh_uid,
              no_of_blocks: neighData.no_of_blocks,
              occupancy: neighData.occupancy,
              shape_area: neighData.shape_area
                ? this.fnc.modifyInt({
                  value: neighData.shape_area,
                  roundUp: true,
                })
                : '',
              status: neighData.status,
              total_properties: this.loadDataService.getPropertyCount(neighData.neigh_uid, neighData.total_properties),//neighData.total_properties,
              zone: neighData.zone ? this.fnc.renderZone(neighData.zone) : '',
              slug: neighData.slug
            };
            list.push(popupdata);
          });
          this.neighList.next(list);
        } 
      }, error: (err)=>{}});
  }

  //get Blocks
  getBlocks(neighUid) {
    let list = [];
    this.loader.next(true);
    let count =0;
    this.api
      .getData(
        `blocks/details?neigh_uid=${neighUid}&get_geom=false&get_centroid=true`
      )
      .pipe(take(1)).subscribe({
        next: (ele: any) => {
          this.loader.next(false);
          if (ele && ele.data) {
            ele.data.map((block_data: any) => {
              let centroid: any = this.fnc.getlanlon(block_data.centroid);
              let blockData = {
                block_id: block_data.block_id,
                block_name:
                  block_data.block_name &&
                    block_data.block_name[this.api.language_id]
                    ? block_data.block_name[this.api.language_id]
                    : block_data.block_name['1'],
                block_uid: block_data.block_uid,
                lat: centroid.lat,
                lon: centroid.lon,
                media: block_data.entity_media,
                neigh_uid: block_data.neigh_uid,
                occupancy: block_data.occupancy,
                shape_area: block_data.shape_area
                  ? this.fnc.modifyInt({
                    value: block_data.shape_area,
                    roundUp: true,
                  })
                  : '',
                status: block_data.status,
                total_properties: this.loadDataService.getPropertyCount(block_data.block_uid, block_data.total_properties), //block_data.total_properties,
                zone: block_data.zone
                  ? this.fnc.renderZone(block_data.zone)
                  : '',
              };
              count+=block_data.total_properties;
              list.push(blockData);
            });
            this.total_property_count_block.next(count)
            this.blockList.next(list);
            this.backblockList.next(list);
          } else {
            this.total_property_count_block.next(0)
          }
        },
        error: () => {
          this.total_property_count_block.next(0)
          this.loader.next(false);
        }
      });
  }
  // select neighbourhoods
  async select_district(district: any, is_remove: boolean = true, is_back: boolean = false, block: any = null, onload = false, browser_back = false) {
    if (!district) return;

    this.resetTabDetails.next(true)
    this.selected_neigh_uid = district.neigh_uid;
    if (is_remove) {
      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
      this.fnc.removeQString(['bid']);
      //this.fnc.changeUrl('nid', district.neigh_uid, this.fnc.removeSpace(district.neigh_name));
    }
    if (is_back) {
      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
      this.fnc.removeQString(['bid']);
      //this.fnc.changeUrl('nid', district.neigh_uid, this.fnc.removeSpace(district.neigh_name));
    }


    // if (onload) await this.loadDataService.prooertyCount(district.neigh_uid);

    this.selected_section.next('neighbourhoods');
    this.selected_block.next('');
    this.selected_property.next(null);
    this.savedPropertyFloor.next('');
    this.valuation_data.next(null);
    this.show_on_map.next(false);
    let neigh_data = district;
    if (is_remove) {
      //this.backblockList.next('');
      try {
        let resolver_data;
        this.activatedRoute.data.subscribe((res: any) => {
          resolver_data = res.data;
        })
        if (resolver_data.slug == district.slug) {
          let neigh = resolver_data;
          let latlon: any;
          if (neigh.centroid) {
            latlon = this.fnc.getlanlon(neigh.centroid);
          }
          neigh = { ...{ lat: latlon.lat, lon: latlon.lon }, ...neigh };
          neigh_data = this.loadDataService.create_neigh_data(neigh);
        }

      } catch (error) {
        //console.log(error);

      }
    }
    this.selected_neigh.next(district);
    if (is_back && block) {
      this.blockList.next(block);
    } else {
      this.getBlocks(neigh_data.neigh_uid);
    }


    /*this.getBlocks(neigh_data.neigh_uid);*/
    if (this.landing_obj) {

      this.landing_obj.select_neighbourhoods(neigh_data);
      let data = {
        action_type: UserAction.neighborhood.Action_ID,
        data: {
          uid: neigh_data.neigh_uid,
          duration: 0,
          neigh_name: neigh_data.neigh_name,
        },
        neighbourhood: neigh_data.neigh_name,
      };
      if (!is_back) {
        this.user_activity(data);
      }
    }


  }

  // select block
  async select_block(block: any, is_remove: boolean = true, is_back: any = false, prop: any = null) {
    if (!block) return;
    this.buildupHistory.next(null);
    this.landHistory.next(null);
    this.resetTabDetails.next(true)
    this.buildupHistory.next('');
    if (is_remove) {

      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
      this.fnc.changeUrl('bid', block.block_uid);
    }
    this.selected_property.next(null);
    this.valuation_data.next(null);
    this.land_valuation.next('');
    this.savedPropertyFloor.next('');
    this.show_on_map.next(false);
    this.selected_section.next('block');
    let block_data: BlockData = new BlockData(block);

    if (is_back) {
      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
      this.fnc.changeUrl('bid', block.block_uid);
      this.propertyList.next(prop);
    }

    if (is_remove) {
     this.loadDataService
        .getBlockData(block.neigh_uid, block.block_uid)
        .then((block: any) => {
          if (block) {
            let bdata = block[0];
            this.total_property_count.next(bdata.total_properties)
            let latlon: any;
            if (bdata.centroid) {
              latlon = this.fnc.getlanlon(bdata.centroid);
            }
            bdata = { ...{ lat: latlon?.lat, lon: latlon?.lon }, ...bdata };
            block_data = this.loadDataService.create_block_data(bdata);
          } else {
            this.total_property_count.next(0)
          }
        }).catch(err => { this.total_property_count.next(0)});
    }
    this.selected_block.next(block_data);
    this.selected_back_block.next(block_data);

    if (this.landing_obj) {
      this.landing_obj.select_block(block_data);
    }
    let el = this.neigh_data;
    if (!is_back) {
      let data = {
        action_type: UserAction.block.Action_ID,
        block: block_data.block_name,
        data: {
          uid: block_data.block_uid,
          duration: 0,
          block_name: block_data.block_name,
        },
        neighbourhood: el?.neigh_name,
      };
      this.user_activity(data)
    };
    if (!is_back) this.landing_obj.getNearbyCategories();
  }


  // selected property
  async select_property(prop: any, is_remove: boolean = true, reload: boolean = false) {
    if (!prop) return;
    this.buildupHistory.next(null);
    this.landHistory.next(null);
    if (is_remove) {
      this.preference = null;
      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
    }

    this.selected_property.next(null);
    this.landing_obj.selected_property = '';
    this.selected_section.next('property');
    //this.valuation_data.next('');
    this.land_valuation.next('');
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(async () => {
        try {
          let filter_data = {
            property_id: prop.property_id,
          };
          this.loader.next(true);
          let property: any;
          if (reload) {
            property = prop;
          } else {
            let propertyList = await this.loadDataService.getProperties(filter_data);
            property = propertyList ? propertyList[0] : null;
          }
          this.loader.next(false);

          if (property) {
            //console.log(property);
            this.landing_obj.is_saved = false;
            this.fnc.changeUrl('pid', prop.property_id);
            this.selected_property.next(property);
            this.landing_obj.pantoMap(prop, 20, 'property');
            this.landing_obj.select_property_marker(prop);
            // this.isFloorSaved(property.property_id);

          }

          let _block, _neigh;
          this.selected_block.subscribe((block) => {
            if (block) {
              _block = block;
            }
          });
          _neigh = this.neigh_data;
          let data = {
            action_type: UserAction.property_visit.Action_ID,
            block:
            _block && _block?.block_name
                ? _block.block_name
                : '',
            data: { latlng: { lat: prop.lat, lon: prop.lon } },
            geom: `POINT(${prop.lon} ${prop.lat})`,
            neighbourhood:
            _neigh && _neigh?.neigh_name
                ? _neigh.neigh_name
                : '',
            property_uid: property.property_uid
          };
          this.user_activity(data);

        } catch (error) {
          //console.log(error);

        }
      }, 200);
    }
  }
  // select city
  select_city(city: any, is_remove: boolean = true) {
    this.selected_neigh_uid = null;
    if (!city) return;
    this.resetTabDetails.next(true)
    if (is_remove) {
      this.browserService.clearSession();
      this.preference = null;
      // this.fnc.removeQString(['nid']);
      this.fnc.removeQString(['preference']);
      this.fnc.removeQString(['pid']);
      this.fnc.removeQString(['bid']);
    }

    this.selected_section.next('city');
    this.selected_block.next('');
    this.selected_neigh.next('');
    this.selected_property.next('');
    this.savedPropertyFloor.next('');
    this.valuation_data.next(null);
    this.show_on_map.next(false);
    if (this.landing_obj) {
      this.landing_obj.selected_block = null;
      this.landing_obj.selected_neigh = null;
      this.landing_obj.is_saved = false;
    }

    let latLon = [city.latitude, city.longitude];
    if (this.landing_obj && this.landing_obj.map) {
      this.landing_obj.remove_layer('block');
      this.landing_obj.remove_layer('nearby');
      this.landing_obj.remove_layer('markers');
      this.landing_obj.remove_layer('prop_marker');
      this.landing_obj.remove_layer('wms_layer');
      this.landing_obj.remove_layer('wms_layer_all');
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.landing_obj.pantoMap(latLon, 13, 'city');
        }, 500);
      }
    }
    // this.router.navigateByUrl(`em/${this.city_code}`)

  }
  
  ///user activety log
  user_activity(data: any, valuationData?: any) {
    let latlon: any = this.browserService.getLocalValue('latlon')
      ? JSON.parse(this.browserService.getLocalValue('latlon'))
      : null;
    let body = {
      user_id: this.api.user_id ? this.api.user_id : null,
      broker_id: this.api.broker_id ? this.api.broker_id : null,
      action_type: data.action_type,
      geom: data.geom ? data.geom : null,
      neighbourhood: data.neighbourhood,
      block: data.block ? data.block : null,
      parcel: data.parcel ? data.parcel : null,
      data: data.data,
      user_country: latlon ? latlon.user_country : null,
      lat: latlon ? latlon.latitude : data.lat ? data.lat : null,
      lon: latlon ? latlon.longitude : data.lon ? data.lon : null,
      city: latlon ? latlon.city : data.city ? data.city : null,
      session_id: this.api.session_id,
      property_uid: (data?.property_uid) ? data?.property_uid : null
    };
    if (valuationData) {
      valuationData['property_id'] = data.property_id;
      valuationData['unit_sub_type_id'] = data.unit_sub_type_id;
      valuationData['unit_sub_type_name'] = data.unit_sub_type_name;
      valuationData['unit_sub_type_label'] = data.unit_sub_type_label;
      valuationData['unit_type_name'] = data.unit_type_name;
      body['valuation'] = valuationData ? valuationData : null;
    }

    this.api.postData(`userActivity/add`, body).pipe(
      distinctUntilChanged(),
    ).subscribe({
      next: (res: any) => { },
      error: (err) => {
        //console.log(err);
      }
    });
  }
  async deleteProperty(obj: boolean, propIds?, prop:any='') {
    if (obj) {
      var property_ids = propIds;
      if (!this.api.user_id && !property_ids) return;
      let url = `user-property-delete?`;
      url += `user_id=${this.api.user_id}&ids=${property_ids}&action_type_id=${UserAction.save_property.Action_ID}`;
      this.api.deleteData(url).pipe(take(1)).subscribe((res: any) => {
        if (res && res.status != 500) {
          this.alert.success('Property unsaved successfully');
          this.savedPropertyFloor.next('');
          // this.getSavedProperties(true);
          if(prop){
            prop['is_saved'] = false;
            prop['saved_id'] = null;
            this.updatePropertyStatus.next(prop);
          }
          return true;
        } else {
          this.alert.warn(res.message);
          return false;
        }
      });
    }
  }
}
