export const environment = {
  production: false,
  "city_id":"32",
  "umsUrl": "https://umsapinode.dev10.in/",
  "emUrl": "https://apiem.dev10.in/",
  "WMS_URL": "https://admin.dev10.in/geoserver/",
  "EM_BROKER_API_URL": "https://brkapi.dev10.in/",
  "EM_VALUATION_API_URL": "https://valapi.dev10.in/",
  "user_token": "TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=",
  "to_email":"shahnawaz@estater.com",
  "client_url":"https://e2.dev10.in/",
  // "client_url":"http://localhost:4200/",
  "resize":"property-image",
  'api_key':'a8f124bb6c4044be9a971284327563c3',
  'mi_api_key':'00cc456ac4963d000af6c114dcf89825',
  'google_key':'949746472591-en6bduhmd73s7smb2p5a4jb7l1b2qrc1.apps.googleusercontent.com',
  'facebook_key':'2733543023566781',
  'geoestater' : 'https://ged.gisworld.in/',
  'pbf_property_layer': false,
  'miUrl': 'https://miapi.dev10.in/v1/',
  'miApp':'https://markets.dev10.in/',
  'em_ang':'estater-meter-gis',
  'encrypt_key': 'QhdfV7@1764sdXA',
  'recaptchaActive': false,
  'is_production': false,
  'blog_baseurl':'https://blog.dev10.in/'
};
