
export class CityData {
    
    currency_code: String;
    data: any;
    description: any;
    latitude: number;
    longitude: number;
    meta_description: any;
    meta_title: any;
    place_code: String;
    place_id: number;
    place_name: String;
    slug: String;

   constructor(Values:any={}){
       Object.assign(this, Values);
   }
}