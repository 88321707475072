<ng-container>
    <div class="position-relative"><mat-progress-bar *ngIf="loading" style="position: absolute; top: 0;  width: 100%;height: 5px;"
        mode="indeterminate"></mat-progress-bar></div>
    <div class="heading_div">
        <h4 *ngIf="!isMobile">{{'User.Register' | translate}}</h4>
        <span class="new-logo" *ngIf="isMobile"><img src="../../../../../assets/icons/logo_.svg"alt="mobile logo"></span>
        <img class="close pointer close_align"(click)="closePopup()" src="assets/icons/Cross_purple.svg" alt="cross">
    </div>
    <div class="form_block">
        <form [formGroup]="registerForm" class="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
            <div class="form-group">
                <div class="input-group registrerInput_group _direction">
                    <span class="admin-icon input-group-addon registerForm_icon_rtl"></span>
                    <input id="firstName" class="form-control input_direction txt_position" formControlName="firstName" required
                        placeholder="{{ 'User.First_name' | translate }}"
                        [ngClass]="{ 'is-invalid': registerForm.get('firstName').invalid && (registerForm.get('firstName').touched || isSubmited) }" />
                </div>
                <mat-error *ngIf="registerForm.get('firstName').hasError('required') && (registerForm.get('firstName').touched || isSubmited)">
                    {{ 'User.first_name_required' | translate }}
                </mat-error>
            </div>
            
            <div class="form-group">
                <div class="input-group registrerInput_group _direction">
                    <span class="admin-icon input-group-addon registerForm_icon_rtl"></span>
                    <input id="lastName" class="form-control input_direction txt_position" formControlName="lastName" required
                        placeholder="{{ 'User.Last_name' | translate }}"
                        [ngClass]="{ 'is-invalid': registerForm.get('lastName').invalid && (registerForm.get('lastName').touched || isSubmited) }" />
                </div>
                <mat-error *ngIf="registerForm.get('lastName').hasError('required') && (registerForm.get('lastName').touched || isSubmited)">
                    {{ 'User.last_name_required' | translate }}
                </mat-error>
            </div>
            
            <div class="form-group">
                <div class="input-group registrerInput_group _direction txt_position">
                    <span class="email-icon input-group-addon registerForm_icon_rtl"></span>
                    <input id="email" class=" form-control input_direction txt_position" formControlName="email" required
                        placeholder="{{'User.Enter_your_Email' | translate}}" type="email"
                        [ngClass]="{'is-invalid': !this.registerForm.get('email').valid && this.registerForm.get('email').touched}" />
                </div>
                  <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                    <span *ngIf="!email.errors?.required && email.errors?.pattern && email?.errors != null">
                        {{'User.valid_email' | translate}}</span> 
                </mat-error>
            </div>
            <div class="form-group txt_position">
                <div class="input-group registrerInput_group _direction txt_position">
                    <span class="pass-icon input-group-addon registerForm_icon_rtl"></span>
                    <input id="password" type="{{ showVar ? 'text' : 'password'}}"
                        class=" form-control txt_password input_direction txt_position" formControlName="password" required
                        placeholder="{{'User.Password' | translate}}"
                        [ngClass]="{'is-invalid': !this.registerForm.get('password').valid && this.registerForm.get('password').touched}" />
                    <div class="eye_img">
                        <img alt="eye closed"
                            src="{{ showVar ? 'assets/icons/eye_open_grey.svg' : 'assets/icons/eye_closed.svg'}}"
                            (click)="toggleEyeImage()" class="{{ showVar ? 'open' : 'close'}}" />
                    </div>
                </div>
                <!--  -->
                <mat-error *ngIf="registerForm.controls['password'].hasError('minlength'); else hasNumber">
                    {{'User.password_message2' | translate}}
                </mat-error>
                <ng-template #hasNumber>
                  <mat-error *ngIf="registerForm.controls['password'].hasError('hasNumber'); else hasCapitalCase">
                    {{'User.password_message3' | translate}}
                  </mat-error>
                </ng-template>
                <ng-template #hasCapitalCase>
                  <mat-error *ngIf="registerForm.controls['password'].hasError('hasCapitalCase'); else hasSmallCase">
                    {{'User.password_message4' | translate}}
                  </mat-error>
                </ng-template>
                <ng-template #hasSmallCase>
                  <mat-error *ngIf="registerForm.controls['password'].hasError('hasSmallCase'); else hasSpecialCharacters">
                    {{'User.password_message5' | translate}}
                  </mat-error>
                </ng-template>
                <ng-template #hasSpecialCharacters>
                  <mat-error *ngIf="registerForm.controls['password'].hasError('hasSpecialCharacters')">
                    {{'User.password_message6' | translate}}
                  </mat-error>
                </ng-template>
                <!--  -->
                <p class="infoPasword">{{'User.password_message' | translate}}
                    <mat-icon matSuffix #tooltip="matTooltip"
                    matTooltip="{{'User.password_message8' | translate}}"
                    matTooltipPosition="above"
                    >info</mat-icon></p>
            </div>
            <div class="form-group txt_position">
                <div class="input-group registrerInput_group _direction txt_position">
                    <span class="pass-icon input-group-addon registerForm_icon_rtl"></span>
                    <input id="confirmPassword" type="password" class="form-control input_direction txt_position"
                        formControlName="confirmPassword" required placeholder="{{'User.ConfirmPassword' | translate}}"
                        [ngClass]="{'is-invalid': !this.registerForm.get('confirmPassword').valid && this.registerForm.get('confirmPassword').touched}" />
                </div>
                <mat-error *ngIf="registerForm.controls['confirmPassword'].hasError('NoPassswordMatch') && !registerForm.controls['password'].hasError('required')">
                    {{'User.password_message7' | translate}}
                  </mat-error>
            </div>
            <div class="chk_privacypolicy">
                <label class="switch switch-3">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                </label>
                <span class="txt_switch">
                    {{'Home.create_an_account' | translate}} 
                    <a [routerLink]="['/terms-of-use'| localize]" class="colr_gray" target="_blank">{{'Home.terms_service' | translate}}</a>
                    <a [routerLink]="['/privacy-policy'| localize]" class="colr_gray" target="_blank">{{'Home.privacy_policy' | translate}}</a>
                    {{'Home.and_our_default' | translate}} <span
                        class="colr_gray">{{'Home.notification_settings' | translate}}</span>
                </span>
                <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
            </div>
            <div class="">
                <p *ngIf="errormessege" class="text-danger error_msg"><span class="info_icon"></span>{{errormessege}}</p>
                <!-- <p *ngIf="successmessege" class="text-success">{{successmessege}}</p> -->
            </div>
            <div class="form-group text-center">
                <button class="submit-buttton btn" type="submit" [disabled]="loading">{{'User.Register' | translate}} <div class="button_loader" *ngIf="loading"></div></button>
            </div>
            <!-- <div *ngIf="registerForm.get('email').touched && registerForm.get('email').touched">
            <div *ngIf="registerForm.get('email').errors.email">Please enter a valid email address</div>
        </div>
        <div *ngIf="registerForm.get('email').touched && registerForm.get('email').invalid">
            <div *ngIf="registerForm.get('email').errors.email">Please enter a valid email address</div>
        </div> -->
        </form>
        <app-social-login (close)="closePopup()"></app-social-login>
        <div class="bottom_login text-center">
            <p>{{'User.Have_an_account' | translate}} <span (click)="login_()">{{'User.Login_here' | translate}}</span>
            </p>
        </div>
    </div>
</ng-container>