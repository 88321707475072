import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/core/components/alerts/alert.service';
import { } from '@angular/core';
import { BrowserService } from 'src/app/services/browser.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  registerForm: UntypedFormGroup;
  isMobile = false;
  @Output() closePopup = new EventEmitter();
  loader: boolean;
  isSubmited: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder, private api: ApiServiceService, private alertService: AlertService, private browserService: BrowserService) {
    this.isMobile = this.api.isMobile;
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: [null, Validators.compose([ Validators.required,Validators.maxLength(65),Validators.minLength(2)])],
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      feedback_message: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(250)]]
    });
  }
  onSubmit(formvalue: any) {
    this.isSubmited = true;
    if (this.registerForm.invalid) {
      let valAry = Object.keys(formvalue);
      valAry.map(ele => {
        if (formvalue[ele] == null || formvalue[ele] == undefined || formvalue[ele] == '') {
          let idobj: any = this.browserService.getElements('ById', ele);
          idobj.classList.add("is-invalid");
        }
      })
      return;
    }
    let body = {
      "from_email": formvalue.email,
      "name": formvalue.name,
      "feedback_message": formvalue.feedback_message,
      "to_email": environment.to_email

    }
    this.loader = true;
    this.api.postData('feedbackMail', body).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.isSubmited = false;
        if (res.status == 1) {
          this.registerForm.reset();
          this.alertService.success(res.message);
          this.closePopup.emit();
        } else {
          this.alertService.warn(res.message);
        }
      },
      error: (error) => {
        this.isSubmited = false;
        this.loader = false;
        //console.log(error)
      }
    })
  }
  back() {
    this.browserService.getWindowHistory();
  }
  closebackeedPopup() {
    this.closePopup.emit();
  }
  ngOnDestroy(): void {
    this.isMobile;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
