<footer>
    <div class="footer-position">
        <ul class="footer-list">
            <li><a [routerLink]="['/']" target="_blank">Estater</a></li>
            <li><a [routerLink]="['/'+ urlLang +'/estater-meter-overview']">{{'Home.about' | translate}}</a></li>
            <li><a [routerLink]="['/'+ urlLang +'/estater-what-we-do']" >{{'Home.service' | translate}}</a></li>
            <li><a [routerLink]="['/'+ urlLang +'/estater-geo-estaters']" >{{'Home.geo_Estater' | translate}}</a></li>
            <!-- <li><a (click)="goToPage('/terms')">{{'Home.terms_Of_Use' | translate}}</a></li>
            <li><a (click)="goToPage('/privacy')">{{'Home.privacy_Policy' | translate}}</a></li>
            <li><a href="https://estater.com/blog/" target="_blank">{{'Home.blog_&_Media' | translate}}</a></li> -->
            <!-- <li><a (click)="goToPage('/contact')">{{'Home.contact_Us' | translate}}</a></li> -->
            <!-- <li (click)="startTour()" *ngIf="currentpage == '/landing'"><a>{{'Home.start_A_Tour' | translate}}</a></li> -->
            <!-- <li *ngIf="current_city" (click)="goToCity()" class="city"><a>{{'city.'+current_city | translate}}</a></li> -->
            <!-- <li class="lang"><app-select-language></app-select-language></li> -->
        </ul>
        <ul  class="footer-list footer_social">
            <li><a href="https://www.instagram.com/the.real.estater?igsh=dGFwOWl2cjc0emxk" target="_blank"><img src="assets/social_icon/Instagram.svg" alt="Instagram"></a></li>
            <li><a href="https://www.facebook.com/people/Estatercom/100070864224953/" target="_blank"><img src="assets/social_icon/Facebook.svg" alt="Facebook"></a></li>
            <li><a href="https://twitter.com/thereal_estater" target="_blank"> <img src="assets/social_icon/Twitter.svg" alt="Twitter"></a></li>
            <li><a href="https://www.linkedin.com/company/estater/about/" target="_blank"><img src="assets/social_icon/Linkdin.svg" alt="linkedin"></a></li>
            <li><a href="https://www.youtube.com/@TheRealEstater" target="_blank"><img src="assets/social_icon/Youtube.svg" alt="youtube"></a></li>
        </ul> 
    </div>
</footer>