export class Alert {
    type?: AlertType;
    message?: string;
}

export enum AlertType {
    Success,
    Errors,
    Info,
    Warning
}
