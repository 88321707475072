import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,Validators  } from '@angular/forms';
import { AlertService } from '../alerts/alert.service';
import { ApiServiceService } from '../../../services/api.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-2';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-submit-query-form',
  templateUrl: './submit-query-form.component.html',
  styleUrls: ['./submit-query-form.component.scss'],
})
export class SubmitQueryFormComponent implements OnInit {

  @Input() title='';
  private unsubscribe$:Subject<void>=new Subject<void>();
  contactForm: UntypedFormGroup;
  submitted = false;
  message = false;
  error = false;
  textmessage = '';
  formData: any;
  @ViewChild("myFileInput") myFileInput;
  loader: boolean;
  file_name: string='';

  
  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public cuntry: string = 'India';
  public preferredCountries: CountryISO[] = [
    CountryISO.Bahrain,
    CountryISO.India,
    CountryISO.Kuwait,
    CountryISO.SaudiArabia,
    CountryISO.Switzerland,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];
  phoneNumber: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiServiceService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      type: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(65), Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(250)]],
      phone_number: ['', Validators.required],
    });
  }


  /**
   *uploadImage function to upload image
   *@param event
   *@param rowindex
   */
   uploadImage(event) {
    // Open image cropper dialog;
    const fileBeforeCrop:File = event.target.files[0];
    if(fileBeforeCrop){
      let formdata: FormData= new FormData();
      let size:any = (fileBeforeCrop.size / (1024 * 1024)).toFixed(2); //MB
      if(size > 2){
        this.alert.warn('File size is can not be more then 2MB')
        return;
      }
  
      this.file_name=fileBeforeCrop.name;
      let fileToUpload:File= new File([fileBeforeCrop], `${new Date().getTime()}_${fileBeforeCrop.name}`, {type: fileBeforeCrop.type})
      formdata.append('file', fileToUpload);
      this.formData = formdata;
    }else{
      this.file_name='';
      this.formData=null;
      this.myFileInput.nativeElement.value = "";
    }
   
  }


  /**
   * this is a function to use submit query 
   */
  save(value) {

    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    if(!this.formData){
      this.formData=null;
    }
    let data={
      description: value.message
    }
    let country_code = (value.phone_number) ? value.phone_number.dialCode : '';
    let phone_number = (value.phone_number?.e164Number) ? value.phone_number.e164Number.split(country_code)[1] : '';
    
    let query = `contact-us?name=${value.name}&subject=${value.subject}&email=${value.email}&mobile_number=${country_code}-${phone_number}&type=${value.type}&data=${JSON.stringify(data)}`
   

    this.loader = true; 
       this.api.postUmsfile(query, this.formData).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next : (res:any)=>{
        this.loader = false;
        this.submitted=false;
        if(res && res.status == 201){
          this.contactForm.reset();
          this.file_name='';
          this.formData=null;
          this.myFileInput.nativeElement.value = "";
          this.alert.success(res.message)
        }
        else{
          this.alert.warn(res.message);
        }
      },
      error: (err)=>{
        this.alert.warn(err.error?.message);
        this.submitted=false
        this.loader = false;
      }})
    this.error = false;
  }

    /**
   * this is a function to use reset submit query form
   */
  reset(){
    this.contactForm.reset();
    this.submitted =false;
    this.file_name='';
    this.formData=null;
    this.myFileInput.nativeElement.value = "";
  }
  ngOnDestroy(){
    this.api;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }
}
